import React from "react";
import "./terms.css";
import Navbar from "../../layouts/navbar/Navbar";
import SideImage from "../../assests/images/terms.jpg";
import { TiTick } from "react-icons/ti";
import Logo from "../../assests/svg/taxi-another.svg";
import { Link } from "react-router-dom";

const TermsPage = () => {
  const lang = localStorage.getItem("lang");
  return (
    <div>
      <Navbar />
      <div className="container mt-5">
        <div className="row my-5">
          <div className="col  term">
            <h4>
              {lang === "arabic" ? "الشروط والأحكام" : "Terms & Conditions"}
            </h4>
            <div className="head-policy"></div>
            <p className="mt-4">
              {lang === "arabic"
                ? `مرحبا بكم في تطبيق "نكفيك". يعمل التطبيق كمنصة إلكترونية وسيطة بين مزود الخدمة من ناحية والعميل من ناحية أخرى، حيث يسمح للعميل بالتواصل مع مزود الخدمة للوصول الى طلبه، كما
                يقدم التطبيق خدمات الاشتراك والرسوم  وذلك كله وفق الشروط والأحكام الآتية:
                `
                : `Welcome to the "NKFEEK نكفيك" application. The application acts as an intermediate electronic platform between the service provider on the one hand and the customer on the other, as it allows the customer to communicate with the service provider to reach his request, and The application provides subscription services and fees, all according to the following terms and conditions:`}{" "}
            </p>

            <h4>
              {lang === "arabic"
                ? "تعريفات المصطلحات في هذه الاتفاقية، وما لم يقتضي السياق خلاف ذلك"
                : "Definitions Of Terms In This Agreement And, Unless The Context Otherwise Requires"}
            </h4>
            <p>
              {lang === "arabic"
                ? `يكون للمصطلحات التالية المعاني المشار إليها. "التطبيق" أو "نحن" أو "ضمير المتكلم" أو "ضمير الملكية" يشير إلى تطبيق "نكفيك" "المستخدم" أو "أنت" أو "ضمير الملكية للمخاطب" يشير إلى كل شخص يقوم بزيارة أو استخدام التطبيق بأي شكل من الأشكال، سواء كان مزود خدمة أو عميل أو غيرذلك. "مزود الخدمة" هو كل شخص يقوم بالتوصيل من المتاجر أو توصيل الطرد أو توصيل الإرساليات بين المدن بناءً على طلب العميل او عرض خدماته بما يتطلب النظام وهو معني امام الجهات المختصة فيما يقدمة خدمة وفق النظام.`
                : `The following terms shall have the meanings indicated. "App", "we", "us" or "us" refers to the "NKFEEK نكفيك" application, "user", "you" or "addressee's proprietary pronoun" refers to each person who visits or uses the application in any way, whether a service provider, customer or otherwise. "Service Provider" is any person who delivers from stores, delivers parcels or delivers consignments between cities at the request of the customer or offers his services as required by the system and is concerned before the competent authorities in what he provides a service in accordance with the system.`}
            </p>
            <p>
              {lang === "arabic"
                ? `"العميل" يشير إلى كل شخص يطلب من مزود الخدمة بما يتوافق مع طلبه `
                : `"Customer" refers to each person who requests the Service Provider in accordance with his request `}
            </p>
            <p>
              {lang === "arabic"
                ? `"المحتوى" يشير إلى كافة النصوص والمعلومات`
                : `"Content" refers to all text and information`}
            </p>
            <p>
              {lang === "arabic"
                ? `والبيانات والصور والشعارات والفيديو والإعلانات وكل ما يتم نشره بواسطة المستخدم عبر التطبيق. "الاتفاقية" تشير إلى الشروط والأحكام وسياسة الخصوصية وكافة صفحات التطبيق الرئيسية والفرعية ووصف الخدمات وكافة المعلومات ومحتوى الخدمات وكافة التعليمات والاتفاقات الخاصة التي تتم بين التطبيق من ناحية وأي من مستخدمي التطبيق من ناحية أخرى، وكذلك العقود المرتبطة بالشروط والأحكام. "أطراف الاتفاقية" يشير إلى التطبيق من ناحية كـ (طرف أول في العلاقة التعاقدية وأي شخص يزور التطبيق أو يستخدمه أو يسجل حساب بالتطبيق أو يقدم أي من خدمات التطبيق أو يستفيد من خدماتنا بأي شكل من الأشكال من ناحية أخرى كـ (طرف ثان في العلاقة التعاقدية`
                : `Data, images, logos, video, advertisements and everything posted by the user through the application. "Agreement" refers to the terms and conditions, the privacy policy, all main and sub-pages of the application, description of the services, all information and content of the services, all instructions and special agreements made between the application on the one hand and any of the users of the application on the other hand, as well as contracts related to the terms and conditions. "Parties to the Agreement" refers to the Application on the one hand as (a first party to the contractual relationship and any person who visits or uses the Application or registers an account with the Application or provides any of the Services of the Application or benefits from our Services in any way on the other hand as (a second party to the contractual relationship).`}
            </p>

            <h4>
              {lang === "arabic"
                ? "النطاق القانوني لخدماتنا "
                : "The Legal Scope Of Our Services"}
            </h4>
            <ul className="my-4 ">
              <li className="my-4  ">
                {" "}
                {lang === "arabic"
                  ? `يعمل تطبيق "نكفيك" كمنصة الكترونية وسيطة تتيح التواصل بين العميل و مزود الخدمة، ولا يتعدى دورنا ذلك، ولا نملك أي سلطة إشرافية أو رقابية على مزود الخدمة، ولا نحن ممثلين ولا وكلاء عن العملاء، ولا تنطبق علينا أي من أحكام قانون العمل أو الوكالة أو الكفالة أو غيرها من الأحكام القانونية المتعلقة بالمسئولية القانونية عن أفعال التابعين أو الغير، وأنتم توافقون صراحة على طبيعة عملنا هذه ودورنا هذا وتتعاملون عبر التطبيق من هذا المنطلق`
                  : `The "NKFEEK نكفيك" application works as an intermediary electronic platform that allows communication between the customer and the service provider, and our role does not exceed that, and we do not have any supervisory or supervisory authority over the service provider, nor are we representatives or agents of customers, and none of the provisions of the labor law, agency, sponsorship or other legal provisions related to legal responsibility for the actions of followers or third parties apply to us, and you expressly agree to the nature of our work and this role And you deal with the application from this point of view.`}
              </li>

              <li className="my-4">
                {" "}
                {lang === "arabic"
                  ? ` لا يعد تطبيق "نكفيك" مزود خدمة انترنت، ولا متعهد إيواء، ولا موفر محتوى معلوماتي، ولا يعامل كناشر لأي محتوى يتم نشره عبر التطبيق أو من خلال أي خاصية متاحة للتواصل عبر التطبيق، بما في ذلك الرسائل والدردشة وغيرها. `
                  : `The NQFIC Application is not an Internet service provider, a shelter contractor, nor an information content provider, nor is it treated as a publisher of any content published through the Application or through any feature available for communication through the Application, including messages and chat and others. `}
              </li>

              <li className="my-4 text-justify">
                {" "}
                {lang === "arabic"
                  ? `نت تعلم وتقر بالموافقة على إعفاء تطبيق "نكفيك من أي مسئولية تقع عن عمل الغير،  ، سواء كان مستخدمًا، أو مندوب توصيل او سائق ، أو عميل أو غير ذلك.`
                  : `	You know and acknowledge the agreement to exempt the application " NKFEEK نكفيك " from any liability incurred for the work of others, whether he is a user, a delivery person, a driver, a customer or otherwise.`}
                .
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? `لطبيعة القانونية للشروط والأحكام `
                : `The Legal Nature Of The Terms And Conditions `}
            </h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? `	تعد هذه الاتفاقية الاتفاق الكامل والنهائي بين تطبيق "نكفيك  من ناحية، وأي شخص يقوم بزيارة التطبيق أو يستخدمه أو يستخدم أي من خصائصه أو ميزاته من ناحية أخرى. `
                  : `This agreement is the entire and final agreement between the "NKFEEK نكفيك" application on the one hand, and anyone who visits or uses the application or uses any of its features or features, on the other hand. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تعد هذه الاتفاقية عقدًا صحيحًا مكتملاً الشروط والأركان القانونية، نافذًا في مواجهة جميع الأطراف المذكورة، وأحكامه والتزاماته ملزمة لهم جميعًا، ولا يجوز لأحد التحلل منها أو إنهائها طالما أنتجت آثارها القانونية. `
                  : `This Agreement shall be a valid contract with full legal conditions and elements, effective against all the said parties, and its provisions and obligations shall be binding on all of them, and no one shall derogate from it or terminate it as long as it produces its legal effects`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يقر جميع الأطراف أن هذه الاتفاقية تشكل كامل الاتفاق بينهم، وقد اتفقوا على أنهم لم يعتمدوا على أي تأكيدات، شفوية كانت أم كتابية، في الموافقة على هذه الاتفاقية بخلاف الأحكام المبينة فيها. `
                  : `ALL PARTIES ACKNOWLEDGE THAT THIS CONVENTION CONSTITUTES THE ENTIRETY OF THEIR AGREEMENT AND HAVE AGREED THAT THEY HAVE NOT RELIED ON ANY ASSURANCES, ORAL OR WRITTEN, IN AGREEING TO THIS CONVENTION OTHER THAN THE PROVISIONS SET FORTH THEREIN. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يعد وصف الخدمات والصفحات الفرعية للتطبيق التي تم إعدادها من قبل تطبيق "نكفيك" جزء لا يتجزأ من هذه الاتفاقية.`
                  : `The description of the services and sub-pages of the application prepared by the application "NKFEEK نكفيك" is an integral part of this agreement. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يكون للتعديلات الواردة على هذه الاتفاقية نفس الحكم والأثر القانوني لهذه الاتفاقية`
                  : `Amendments to this Convention shall have the same provision and legal effect as this Convention.`}
              </li>
            </ul>

            <h4>
              {lang === "arabic" ? `توفير الخدمات ` : `Providing Services`}
            </h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? `	يسعى التطبيق بشكل دائم لتوفير خدماته الإلكترونية بالشكل الأمثل، ويستعين في سبيل ذلك بكافة الأدوات والمعدات والأجهزة والبرامج وسبل الحماية اللازمة لتوفير الخدمات، إلى جانب اتخاذ الإجراءات القانونية اللازمة.`
                  : `The application constantly seeks to provide its electronic services in an optimal manner, and for this purpose it uses all the tools, equipment, devices, programs and means of protection necessary to provide services, in addition to taking the necessary legal measures. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	التطبيق لا يضمن أن تعمل الخدمات أو جزء منها على كافة أجهزة الجوال، كما أنه بالنسبة للأجهزة التي يعمل عليها التطبيق فقد يحدث تأخيرات أو أعطال راجعة إلى خلل ما في شبكة الانترنت وبالتالي فأنت تعفينا من المسئولية عن كل ذلك. `
                  : `The application does not guarantee that the services or part of them will work on all mobile devices, and for the devices on which the application works, delays or malfunctions may occur due to a defect in the Internet, and therefore you exempt us from responsibility for all of that. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` في سبيل تقديم خدماتنا نقدم الدعم الكامل للعميل ومزود الخدمة  والمستخدمين.`
                  : `In order to provide our services, we provide full support to the customer, service provider and users. `}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "الموافقة والأهلية القانونية"
                : `Consent and Legal Capacity`}
            </h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? " 	تقر بأن لديك الأهلية القانونية اللازمة للإبرام والموافقة على هذه الاتفاقية."
                  : `You acknowledge that you have the necessary legal capacity to enter into and agree to this Agreement.`}
              </li>
              <li>
                {lang === "arabic"
                  ? `	يشترط في من يستخدم تطبيق "نكفيك" أن يتوافر فيه الأهلية والقانونية اللازمة لإبرام  العقود ونحن غير مسؤولين عن التحقق من الأهلية أيا من مستخدمي التطبيق`
                  : `Whoever uses the "NKFEEK نكفيك" application is required to have the necessary capacity and legal capacity to conclude contracts and we are not responsible for verifying the eligibility of any of the users of the application `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` باستخدامك خدمات تطبيق "نكفيك" فأنت توافق على هذه الاتفاقية، وتقر بأنك ملزما قانونا بالشروط القبول والأحكام المنصوص عليها في هذه الوثيقة أو تعديلاتها.`
                  : `By using the services of the "NKFEEK نكفيك" application, you agree to this agreement, and you acknowledge that you are legally bound by the terms of acceptance and the provisions set forth in this document or its amendments. `}
              </li>
            </ul>

            <h4>
              {lang === "arabic" ? "التوقيع الرقمي " : `Digital Signature`}
            </h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	في الخدمات التي تتطلب التسجيل: فانه من خلال تسجيلك للحصول على حساب بالتطبيق، أو عن طريق النقر شروط الخدمة عند المطالبة بذلك على التطبيق. يعتبر أنك قد نفذت هذه الاتفاقية وشروط الخدمة الأخرى الكترونيا، وتعد نافذة المفعول قانونًا في مواجهتك من تاريخ تسجيل حسابك أو من تاريخ النقر لقبول شروط الخدمة. `
                  : ` In services that require registration: it is by registering for an account in the application, or by clicking the terms of service when prompted to do so on the application. You are deemed to have implemented this Agreement and the other Terms of Service electronically, and are legally effective against you from the date of registering your account or from the date of clicking to accept the Terms of Service. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	في الخدمات التي لا تتطلب التسجيل: يعد استخدامك لهذه الخدمات موافقة صريحة منك على الشروط والأحكام المنصوص عليها في هذه الوثيقة كافة السياسات الأخرى، وتعد ملزمًا قانونًا بها من تاريخ هذا الاستخدام الاستخدام.`
                  : ` In services that do not require registration: Your use of these services constitutes your express acceptance of the terms and conditions stipulated in this document and all other policies, and you are legally bound by them from the date of such use.`}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "شروط استخدام التطبيق"
                : `Terms of Use of the Application`}
            </h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? ` يشترط أن يسجل الشخص الطبيعي في التطبيق باسمه الحقيقي، وأن يبتعد عن استخدام أي أسماء مستعارة أوغير حقيقية أو مضللة ويجب ان المستخدم بالغاً سن الرشد. وبمجرد قيامك بالتسجيل كشخص طبيعي فأنت تمثل نفسك فقط، فالحساب قائم على الاعتبار الشخصي`
                  : `It is required that the natural person registers in the application in his real name and to stay away from using any pseudonyms or is not true or misleading and the user must be of age. Once you register as a natural person, you represent only yourself, the account is based on personal consideration.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	إذا كنت تسجل بالتطبيق بحساب شخصي فأنت تعلم أنك تمثل نفسك. `
                  : `If you register with the application with a personal account, you know that you represent yourself. `}
              </li>
              <li>
                {lang === "arabic"
                  ? `	يشترط في المستخدم أن يضع صورته الشخصية في حسابه وعدم وضع أي صور للغير أو لا تعبر عن المستخدم. `
                  : `The user is required to put his personal picture in his account and not to put any pictures of others or do not express the user. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	خدمات الموقع متوفرة في كافة أنحاء العالم، وأنت تقر وتوافق على أنك ملزم بكافة الشروط والأحكام المنصوص عليها في هذه الاتفاقية بغض النظر عن الدولة التي تنتمي إليها أو تدخل التطبيق من خلالها أو تستقبل خدمات التطبيق على أرضها. `
                  : `The site services are available all over the world, and you acknowledge and agree that you are bound by all the terms and conditions stipulated in this agreement, regardless of the country to which you belong or enter the application through or receive the application services on its territory. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` تتعهد بإتباع التعليمات الصادرة من التطبيق بشأن حسابك، بما في ذلك طريقة استخدام الحساب أوالخدمات الإضافية، وكذلك كافة محاذير الاستخدام. `
                  : `You undertake to follow the instructions issued by the application regarding your account, including how you use the account or additional services, as well as all usage caveats. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تتعهد بتوفير المعلومات والمستندات اللازمة والتي يطلبها منك التطبيق في أي وقت سواء أثناء الاشتراك معنا وتسجيل حسابك، أو أثناء تقديم الخدمات لك بعد تسجيل حسابك.`
                  : `You undertake to provide the necessary information and documents requested by the application at any time, whether during subscription with us and registration of your account, or during the provision of services to you after registering your account.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يتعهد المستخدم بتحري الدقة عند إدخال المعلومات المطلوب إدخالها في التطبيق، ويكون مسئول عن مراجعة تلك المعلومات بشكل دوري بغرض تصحيحها أو تعديلها أو تجديدها كلما توفرت لديه معلومات جديدة بشأنها، على أن يحتفظ بكافة البيانات والمستندات الدالةعلى ذلك.`
                  : `The user undertakes to investigate accuracy when entering the information required to be entered into the application, and he is responsible for reviewing such information periodically for the purpose of correcting, modifying or renewing it whenever new information is available about it, provided that he keeps all data and documents indicating this.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يجب أن تكون جميع المعلومات التي تفصح عنها حقيقية ومحدثة وصحيحة وتعبر عنك و توافق ما هو مطلوب في استمارة التسجيل لدينا. `
                  : `All information you disclose must be true, up-to-date, correct, express and agree with what is required in our registration form. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	إذا قدمت معلومات غير صحيحة أو غير دقيقة أو غير متزامنة أو غير كاملة، أو إذا كان لدينا أسباب معقولة للاشتباه في أن هذه المعلومات غير صحيحة أو غير دقيقة أو غير متزامنة أو غير كاملة أو لا تتوافق مع اتفاقية الاستخدام هذه، ودون الإخلال بأي حقوق أخرى بموجب هذه الاتفاقية أو القانون، سوف نقوم بتعليق عضويتك أوإلغائها.`
                  : `	If you provide information that is untrue, inaccurate, not out of sync or incomplete, or if we have reasonable grounds to suspect that such information is untrue, inaccurate, not synchronized, incomplete or inconsistent with this usage agreement, and without prejudice to any other rights under this agreement or the law, we will suspend or cancel your membership.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يتعهد المستخدم بالمحافظة على المعلومات السرية الخاصة بحسابه، مثل اسم المستخدم وكلمة المرور، وأنه مسئول عن أي إفصاح للغير عن هذه المعلومات، كما أنه مسئول عن أي استخدام يقع من أي شخص قام بالإفصاح له عن هذه المعلومات السرية.`
                  : `The user undertakes to maintain the confidential information of his account, such as the username and password, and that he is responsible for any disclosure to others of this information, and he is also responsible for any use by any person who disclosed this confidential information to him.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يتعهد المستخدم بإبلاغ التطبيق فورًا في حالة اختراق أو سرقة الحساب أو اكتشاف أي استخدام غير قانوني لحسابه بالتطبيق، وذلك حتى نستطيع اتخاذ الإجراءات التقنية اللازمة للمحافظة على الحساب. `
                  : `The user undertakes to inform the application immediately in the event of hacking or stealing the account or discovering any illegal use of his account in the application, so that we can take the necessary technical measures to maintain the account. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا يجوز لك استخدام حساب شخص آخر في أي وقت دون الحصول على موافقة صريحة من تطبيق "نكفيك".`
                  : `You may not use another person's account at any time without the express consent of the "NKFEEK نكفيك" application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يلتزم المستخدم بإخطارنا في حالة اكتشافه لأي استخدام غير قانوني للتطبيق أو لأي من الخدمات أو بطاقات الائتمان.`
                  : `The user is obligated to notify us in the event that he discovers any illegal use of the application or any of the services or credit cards.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	نحتفظ بالحق في إلغاء الحسابات التي لم يتم تأكيدها أو الغير نشطة لفترة طويلة.`
                  : `We reserve the right to cancel accounts that have not been confirmed or are inactive for a long time.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	قد يقترن استخدام تطبيق "نكفيك" بتوفيرخدمات أو محتوى جهات أخرى، والتي لا تخضع لسيطرتنا، وبالتالي فأنت تقر بسريان شروط وأحكام وسياسات خصوصية أخرى على استخدامك لخدمات ومحتوى الجهات غير التابعة لنا.`
                  : `The use of the "NKFEEK نكفيك" application may be associated with the provision of services or content of third parties, which are not under our control, you therefore acknowledge the applicability of the terms, conditions and other privacy policies on your use of services and content non-affiliated entities.`}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "شروط وأحكام العملاء"
                : `Customer Terms and Conditions`}
            </h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	العميل هو المسئول الأول والأخير عن الحساب المرتبط بجواله، ويسأل عن أي استخدام غير مشروع أو غيرمصرح به يتم عن طريق حسابه في التطبيق.`
                  : `	The customer is the first and last responsible for the account associated with his mobile phone, and asks for any illegal or non-use authorized by his account in the application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	العميل يعلم ويقر بأن الخدمات التي يتلقاها عبر التطبيق تتم من خلال مزودين خدمة معروضة لك  دون أي تدخل من تطبيق "نكفيك". `
                  : `The customer acknowledges and acknowledges that the services he receives via the application is done through service providers offered to you without any intervention from application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	العميل وحده هو المسئول عن صحة معلومات الخدمة واهلية  التي يقدمها مزود الخدمة ويتحمل أي مسئولية ناشئة عن التأخير نتيجة الخطأ او نتيجة الخدمة اذا كانت مرضية او غير مرضية  . `
                  : `"NKFEEK نكفيك” The customer alone is responsible for the correctness of the service information and eligibility provided by the service provider and bears any responsibility arising from the delay as a result of the error or as a result of the service if it is satisfactory or unsatisfactory.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يتعهد العميل بأن تكون طلبات خدمتهه من مزود الخدمة من خلال التطبيق قانونية ومتوافقة مع الأنظمة المعمول بها ولا تتضمن أي مواد مخدرة أو مسكرة أو أسلحة أو ذخائر أو أشياء ثمينة  او تصرف غير أخلاقي أو أي أشياء أخرى محظور تداولها وفقًا للأنظمة المعمول بها، على أن يسمح العميل لمزود الخدمة بالاطلاع على هويته الشخصية وفحص الأشياء المطلوب خدمته فيها للتأكد من مطابقتها للاشتراطات السابقة، وتكون مسئولية العميل ومزود الخدمة تضامنية في حالة إخلالهم بهذا الالتزام دون أدنى مسئولية أصلية أو تبعية على التطبيق`
                  : `The customer undertakes that his service requests from the service provider through the application are legal and compatible with the applicable regulations and do not include any narcotic or intoxicating substances, weapons, ammunition, valuables, immoral behavior or any other things prohibited from circulation in accordance with the applicable regulations, provided that the customer allows the service provider to view his personal identity and examine the things required to be served in it.To ensure that it complies with the previous requirements, and the responsibility of the customer and the service provider is joint in the event of their breach of this obligation without the slightest original or ancillary responsibility on the application`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يتعهد العميل بعدم طلب خدمة  بها مواد أو منتجات سريعة الفساد أو التلف أو قابلة للكسر بسهولة لضمان جودة عملية التوصيل ومثلها في تقديم كما يلتزم العميل بعدم إرسال أي أشياء ثمينة أو مبالغ مالية ويتحمل وحده كافة المخاطر في حالة طلب توصيلها من مندوب التوصيل. `
                  : ` The customer undertakes not to request a service with materials or products that are quickly corrupted, damaged or easily breakable to ensure the quality of the delivery process and the same in providing and the customer is also obligated not to send any valuables or amounts of money and bears all risks in the event of requesting delivery from the delivery representative. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يلتزم العميل بسداد قيمة الخدمة وكذلك قيمة أجر المحدد وفي حال عدم دفع القيمة المستحقة للخدمة او في حال عدم استلام المشتريات من قبل مقدم الخدمة يحق لنا حظر العميل وعدم إزالة الحظر عن العميل إلى حين سداد المبالغ المستحقه على العميل. `
                  : ` The customer is obligated to pay the value of the service as well as the value of the specified wage, and in the event of non-payment of the value due for the service or in the event of non-receipt of purchases by the service provider, we have the right to ban the customer and not to remove the ban on the customer until the amounts due from the customer are paid. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يلتزم العميل بأن يكون تقييمه للخدمة المقدمة من قبل مزود الخدمة لا يتضمن أي إساءة أو ألفاظ نابية وأن يكون النقد أو التقييم في حدود المسموح به قانونًا.`
                  : ` The customer is obligated that his evaluation of the service provided by the service provider does not include any abuse or profanity and that the criticism or evaluation is within the limits permitted by law.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يعلم العميل ويقر بأننا لسنا أصحاب عمل ولا نملك أي سلطة رقابية أو إشرافية على مزودي الخدمة، وأنهممستقلون تماما في عملهم. `
                  : ` The customer knows and acknowledges that we are not employers and do not have any supervisory or supervisory authority over the service providers, and that they are completely independent in their work. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يقر العميل بأننا لا نمثله أمام الغير ولا نعتبر وكيلاً عنه وأنه مسئول في مواجهة مزود الخدمة مسئولية شخصية في حالة الإخلال بالشروط والأحكام المنصوص عليها في هذه الاتفاقية أو في حالة الإخلال بالنصوص والأحكام القانونية السارية المفعول داخل الدولة المملكة العربية السعودية. `
                  : ` 	The customer acknowledges that we do not represent him before third parties and we do not consider his agent and that he is personally responsible vis-à-vis the service provider in case of breach of the terms and conditions stipulated in this agreement or in case of breach of the legal texts and provisions in force within the State of Saudi Arabia. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يعلم العميل ويوافق أن التطبيق لا يتحمل أي مسئولية بخصوص فقد الأشياء المطلوب توصيلها أو الاستيلاء عليها أو نقصها أو إصابتها بأي ضرر، وأن دور التطبيق يقتصر على ربط العميل بمزود الخدمة دون أن يتدخل في عملية التوصيل أو تقديم أي ضمان بشأن الأشياء المطلوب توصيلها. `
                  : ` The customer knows and agrees that the application does not bear any responsibility for the loss, seizure, shortage or damage of the things to be delivered, and that the role of the application is limited to linking the customer with the service provider without interfering in the delivery process or providing any guarantee regarding the items to be delivered. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	في حالة تغيير العميل لرقم الجوال الخاص به أو التنازل عنه للغير فانه يتعهد بإبلاغنا بالرقم الجديد وتفعيل الحساب مرة أخرى بطرق التفعيل المعتمدة لدينا. `
                  : ` In the event that the customer changes his mobile number or assigns it to others, he undertakes to inform us of the new number and activate the account again with the activation methods approved by us. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يلتزم العميل بحُسن معاملة مزود الخدمة. `
                  : ` The customer is committed to the good treatment of the service provider. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يلتزم العميل بعدم التواصل مزود الخدمة أو طلب تنفيذ خدمات خارج الإطار المعمول به داخل التطبيق، وفي حالة إخلاله بهذا الالتزام يحق للتطبيق إيقافه بشكل دائم أو مؤقت عن استخدام التطبيق مع احتفاظنا بكافة حقوقنا القانونية الأخرى.`
                  : ` The customer is obligated not to communicate with the service provider or request the implementation of services outside the framework in force within the application, and in the event of a breach of this obligation, the application has the right to suspend it permanently or temporarily from using the application while retaining all our other legal rights. `}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "شروط وأحكام مزود الخدمة "
                : `Terms and Conditions of the Service Provider`}
            </h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	إن تسجيل مزود خدمة لدينا قائم على الاعتبار الشخصي، وبالتالي ليس له التنازل عن الحساب إلى الغير، ولا تفويض غيره في القيام بأعمال التوصيل التي وافق على القيام بها. `
                  : `The registration of a service provider with us is based on personal consideration, and therefore does not assign the account to third parties, no one else is authorized to carry out the delivery work to which he has consented to carry out. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يحظر على مزود الخدمة التسجيل لدينا بأكثر منحساب، وفي حالة اكتشافنا ذلك فلنا كامل الحق في تعليق أو إنهاء حسابهم بالتطبيق. `
                  : `The service provider is prohibited from registering with us with more than one account, and in the event that we discover this, we have the full right to suspend or terminate their account in the application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	مزود الخدمة هو المسئول الأول والأخير عن الحساب المرتبط بجواله، ويُسأل مزود الخدمة عن أي استخدام غير مشروع أو غير مصرح به يتم عن طريق حسابه في التطبيق. `
                  : ` The service provider is the first and last responsible for the account linked to his mobile, and the service provider is responsible for any illegal or unauthorized use made through his account in the application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يقر مزود الخدمة أنه يقوم بأعمال التوصيل والخدمة على مسئوليته الشخصية دون أدنى مسئولية على تطبيق "نكفيك".`
                  : ` The service provider acknowledges that he carries out the delivery and service work at his own risk without any responsibility on the "NKFEEK نكفيك" application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يتعهد مزود الخدمة باستخراج كافة تصاريح العمل اللازمة، طبقًا للقوانين المعمول بها والسارية المفعول داخل المملكة العربية السعودية. `
                  : ` The service provider undertakes to obtain all necessary work permits, in accordance with the laws in force and in force within the Kingdom of Saudi Arabia.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يتعهد مزود الخدمة باستخراج كافة التراخيص والمستندات القانونية اللازمة للمركبة التي يستقلها في التوصيل او الخدمة، ويقر بأن هذه التراخيص والأوراق قد استخرجت من مصادرها الشرعية، وذلك دون أدنى مسئولية على التطبيق، كما يقر بعدم تبعية أي مركبة يستقلها في التوصيل إلى التطبيق، وأنه مسئول عن هذه المركبة مسئولية شخصية ومسئول عن كافة المخالفات والحوادث التي قد تنشأ خلال عملية التوصيل. ويقر بأننا لا نقدم أي نوع من أنواع التأمين على المركبات، ولا نحن مسئولين عن تعويضه أو تعويض الغير عن أي أضرار قد تنشأ خلال عملية التوصيل او تقديم الخدمات. `
                  : ` The service provider undertakes to extract all the necessary licenses and legal documents for the vehicle he is traveling in in the delivery or service, and acknowledges that these licenses and papers have been extracted from their legitimate sources, without any responsibility on the application, and acknowledges that any vehicle he rides is not affiliated with the delivery to the application, and that he is personally responsible for this vehicle and responsible for all violations and accidents that may arise during the delivery process. You acknowledge that we do not provide any kind of vehicle insurance, nor are we responsible for compensating it or indemnifying third parties for any damages that may arise during the delivery process or providing the services.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يجب أن يكون مزود الخدمة حائزا على رخصة قيادة صالحة وسارية المفعول في المدينة التي يباشر نشاطه فيها، ويشترط تجديدها في المواعيد المحددة قانونًا، ويقر مزود الخدمة بصحة وقانونية هذه الرخصة وأنه قد استخرجها من مصادرها الشرعية، ويتحمل أي مسئولية جنائية في حال ظهور ذلك او غيره.`
                  : ` The service provider must have a valid and valid driver's license in the city in which he carries out his activity, and it is required to renew it on the dates specified by law, and the service provider acknowledges the validity and legality of this license and that he has extracted it from its legitimate sources, and bears any criminal responsibility in the event that this or others appear. `}
              </li>
              <li>
                {lang === "arabic"
                  ? `لتزم مزود الخدمة بالقيام بالخدمة المبرم بينه وبين العميل دون أدنى مسئولية قانونية علينا. كما يلتزم مزود الخدمة بالقيام بمهامه طبقًا للاتفاق المبرم بينه وبين العميل دون أدنى مسئولية قانونية علينا. `
                  : ` The service provider is obligated to carry out the service concluded between him and the customer without any legal responsibility on us. The service provider is also obligated to carry out its tasks in accordance with the agreement concluded between it and the customer without any legal liability on us. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يلتزم مزود الخدمة بأن يقوم بعمليات الخدمة داخل المناطق المصرح بها قانونًا ويتعهد بالابتعاد عن أيمناطق محظور دخولها. `
                  : ` The service provider is obligated to carry out service operations within the legally authorized areas and undertakes to stay away from any restricted areas. `}
              </li>
              <li>
                {lang === "arabic"
                  ? `  	يلتزم مندوب التوصيل بالتأكد من الإرساليات والطرد قبل استلامه والتأكد من أنه لا يتضمن أي مواد مخالفة للأنظمة المعمول بها حتى لا يقع تحت طائلة المسائلة القانونية، كما يلتزم بعدم شراء أي مواد أو منتجات محظور تداولها او مواد مختلفة مثل بطاقات مسبقة الدفع، بطاقات الهدايا، تصاريح المرور المجموعات المالية(الطوابع النقود وغيرها) أو أشياء ثمينة ، وفى جميع الأحوال تكون مسئولية مزود الخدمة والعميل تضامنية في حالة إخلالهم بهذا الالتزام دون أدنى مسئولية قانونية أصلية أو تبعية أو تضامنية على التطبيق..`
                  : ` The delivery representative is obligated to verify the consignments and parcel before receiving it and to ensure that it does not include any materials contrary to the applicable regulations so as not to fall under the penalty of legal accountability, and is also obligated not to buy any materials or products prohibited from circulation or various materials such as prepaid cards, gift cards, traffic permits, financial groups (stamps, etc.) or valuables, and in all cases it is the responsibility of the service provider The customer shall be jointly liable in the event of their breach of this obligation without any original, ancillary or joint legal responsibility for the application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يلتزم مزود الخدمة بإحضار فاتورة للمشتريات ااذا كانت خدمته  بشرائها بناءً على طلب العميل. `
                  : ` The service provider shall bring an invoice for purchases if his service is purchased at the request of the customer. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يلتزم مزود الخدمة بالمحافظة على الأشياء التي يقوم التي طلب العميل خدمته بها بأي شكل للخدمة لمصلحة العميل وعدم العبث بها. `
                  : ` The service provider is obligated to maintain the things that the customer requested to serve him in any form for the service for the benefit of the customer and not to tamper with them. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يقر مزود الخدمة بعدم مطالبة العميل بدفع أي مبالغ أخرى زائدة عن تلك المتفق عليها داخل التطبيق. `
                  : ` The service provider acknowledges not to require the customer to pay any other amounts in excess of those agreed upon within the application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يقر مزود الخدمة بأنه ليس عامل لدينا، ولا أننا أصحاب عمل، وليس لنا عليه أي سلطة رقابية أو إشرافية، ويعمل على مسئوليته الشخصية بلا أدنى مسئولية عقدية أو قانونية علينا.`
                  : ` The service provider acknowledges that he is not our employee, nor that we are employers, and we do not have any supervisory or supervisory authority over him, and acts on his personal responsibility without any contractual or legal responsibility on us.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يعلم ويقر مزود الخدمة بعدم تقدمينا لأي تأمين طبي له خلال عملية التوصيل، فالعلاقة مزود الخدمة والتطبيق ليست علاقة عمل. `
                  : ` The service provider knows and acknowledges that we do not provide any medical insurance to him during the delivery process, as the relationship between the service provider and the application is not a business relationship. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يعلم ويقر مزود الخدمة بأننا لا تربطنا بالعميل أي علاقة عمل أو تمثيل قانوني أو وكالة أو غيرها، وفي حالة إخلال العميل بأي حق من حقوق مزود الخدمة فعلى مزود الخدمة اتخاذ كافة شئونه القانونية دون الرجوع إلينا أو علينا.`
                  : ` The service provider knows and acknowledges that we do not have any business relationship, legal representation, agency or other, and in the event that the customer violates any of the rights of the service provider, the service provider must take all his legal affairs without referring to us or us.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` غير مصرح لك باستخدام العمليات التي تتم داخل التطبيق في مواقع أو تطبيقات أخرى، ولا استغلال التقييم الخاص بك في التطبيق في مواقع أو تطبيقات أخرى، ولا أن تجلب إلى تطبيقنا أي تقييمات حصلت عليها في مواقع أو تطبيقات أخرى.`
                  : ` You are not authorized to use the operations that take place within the application in other sites or applications, nor to exploit your rating in the application in other sites or applications, nor to bring to our application any evaluations you have obtained on other websites or applications.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	في حالة تغييرك لرقم الجوال الخاص بك أو التنازل عنه للغير فانك تتعهد بإبلاغنا بالرقم الجديد وتفعيل الحساب مرة أخرى بطرق التفعيل المعتمدة لدينا. `
                  : ` In the event that you change your mobile number or assign it to others, you undertake to inform us of the new number and activate the account again with the activation methods approved by us. `}
              </li>
              <li>
                {lang === "arabic"
                  ? `لتزم مزود الخدمة بحسن معاملة العميل وعدم الإساءة لنا.`
                  : ` The service provider is committed to treating the customer well and not to offend us. `}
              </li>
              <li>
                {lang === "arabic"
                  ? `لتزم مزود الخدمة بعدم التواصل مع العميل أو تنفيذ خدمات خارج الإطار المعمول به داخل التطبيق، وفي حالة إخلاله بهذا الالتزام يحق للتطبيق إيقافه بشكل دائم أو مؤقت عن استخدام التطبيق مع احتفاظنا بكافة حقوقنا القانونية الأخرى.`
                  : ` The service provider is obligated not to communicate with the customer or implement services outside the framework in force within the application, and in the event of a breach of this obligation, the application has the right to permanently or temporarily stop using the application while retaining all our other legal rights.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` مزود الخدمة ان يلتزم بالأمانة في بقيمة وحقيقة العمليات، ويوافق مزود الخدمة في حال اكتشاف اي تلاعب او أساءة الخدمة للعميل بما يسئ للتطبيق بشكل مباشر أو غير مباشر فأن مزود الخدمة سيكون عرضة لإيقاف الخدمات الخاصة به بالأضافة الى حق " نكفيك) تحصيل غرامة أساءة استخدام تبلغ ١٥٠٠ ريال سعودي الأضافة الى حق " نكفيك" بالمطالبة بالتعويضات اللتي نتجت لهذا التلاعب او سوءالاستخدام.`
                  : ` The service provider shall abide by the trust in the value and reality of the operations, and the service provider agrees in the event of discovering any manipulation or abuse of the service to the customer in a way that directly or indirectly harms the application, the service provider will be subject to the suspension of its services in addition to the right of "NKFEEK نكفيك" to collect a fine for misuse of 1500 Saudi riyals in addition to the right of "NKFEEK نكفيك" Claim damages resulting from such manipulation or misuse.`}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "سياسة الدفع " : `Payment Policy`}</h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` يتم حساب مقابل وصولك لطلبك بواسطة التطبيق برسوم يحددها (نكفيك) وله الحق التغير التسعيرة`
                  : ` The fee for your arrival of your order is calculated by the application at a fee determined by (NKFEEK نكفيك) and it has the right to change the pricing. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ""
                  : ` The customer shall pay for the service in addition to the value of the purchases to the service provider as agreed between them in cash. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يقوم العميل بدفع مقابل االخدمة بالإضافة إلى قيمة المشتريات لمزود الخدمة على حسب ما اتفق فيما بينهم نقدًا. `
                  : ` The application does not accept to bear the result of any errors in the payment process made by the customer or the service provider, nor does the application bear the result of any hacking or fraud operations that occur on the credit cards or bank accounts of the customer or the service provider.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا يقبل التطبيق تحمل نتيجة أي أخطاء في عملية الدفع التي يقوم بها العميل أو مزود الخدمة، كما لا يتحمل التطبيق نتيجة أي عمليات قرصنة أو احتيال تقع على البطاقات الائتمانية أو الحسابات البنكية الخاصة بالعميل أو مزود الخدمة `
                  : ` The customer and the delivery representative shall bear all bank fees related to money transfers or payment of the price of recharge cards or commissions.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يتحمل العميل ومندوب التوصيل كافة الرسوم البنكية المتعلقة بعمليات تحويل الأموال أو سداد ثمن بطاقاتالشحن أو العمولات`
                  : ` 	The amounts paid by the customer for the application are final and may not be adjusted by increase or decrease and may not be refunded except with the consent of the application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	المبالغ التي يدفعها العميل للتطبيق نهائية ولا يجوز تعديلها بالزيادة أو النقصان ولا يجوز استردادها إلا بموافقة التطبيق. `
                  : ` The commission paid by the service provider for the application is final and may not be modified by increase or decrease and may not be recovered except with the consent of the application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` العمولة التي يدفعها مزود الخدمة للتطبيق نهائية ولا يجوز تعديلها بالزيادة أو النقصان ولا يجوز استردادها إلا بموافقة التطبيق`
                  : ` 	The application is not responsible in the event that the customer pays a value more than what appeared to him in the invoice or more than what is agreed upon within the application and does not bear any compensation for that matter.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	التطبيق غير مسؤول في حال قام العميل بدفع قيمة أكثر من اللتي ظهرت له في الفاتورة او اكثر ماهو متفق عليه داخل التطبيق ولا تتحمل أي تعويض حيال ذلك الأمر`
                  : ` The application reserves the right to amend the fees and payment policies at any time it deems appropriate. `}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "سياسة الاسترجاع" : `Refund Policy`}</h4>
            <p>
              {lang === "arabic"
                ? `يعلم العميل أن التطبيق لا يبيع أي من المشتريات التي يوفرها مزود الخدمة له وبالتالي فإننا لا نقدم أي سياسات استرجاع، ويتم الاسترجاع وفقا لسياسة الاسترجاع الخاصة بالمحل الذي قام مزود الخدمة بشراء المشتريات منه تقديم الخدمة. `
                : `The customer knows that the application does not sell any of the purchases provided by the service provider to him and therefore we do not provide any return policies, and the return is carried out in accordance with the return policy of the store from which the service provider purchased the purchases from providing the service. `}
            </p>

            <h4>
              {lang === "arabic"
                ? "شروط وأحكام التقييمات "
                : `Terms and Conditions of Evaluations`}
            </h4>
            <p>
              {lang === "arabic"
                ? "يستطيع العميل تقييم مزود الخدمة كما يستطيع مندوب التوصيل تقييم العميل، وتخضع التقييمات للشروط والأحكام الآتية:  "
                : `The customer can evaluate the service provider and the delivery representative can evaluate the customer, and the evaluations are subject to the following terms and conditions: `}
            </p>
            <ul>
              <li>
                {lang === "arabic"
                  ? " يجب أن تكون تقييمات   التقييمات التعرض لأسماء أو أشخاص أو مؤسسات أو شركات أو رموز أو تجريحهم أو الإساءة إليهم بأي شكل من الأشكال، كما يحظر استخدام شعارات دينية أو عبارات محقرة للثقافات أو الشعوب أو تخالف النظام المعمول به. "
                  : ` The evaluations must be to expose the names, persons, institutions, companies or symbols or insult them or offend them in any way, and it is prohibited to use religious slogans or phrases that despise cultures or peoples or violate the applicable system.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	في جميع الأحوال لن يتحمل تطبيق "نكفيك" أي مسئولية بخصوص صدق التقييمات ومدى قانونيتها، ولا نتحمل أي مسئولية عن التقييمات غير المشروعة وهي مسئولية أصحابها فقط`
                  : ` 	In all cases, the application of "NKFEEK نكفيك" will not bear any responsibility regarding the truthfulness and legality of the evaluations, and we do not bear any responsibility for illegal evaluations, which are the responsibility of their owners only. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يحتفظ التطبيق "نكفيك" بكامل الحق في مراجعة وحذف أي تقييمات غير مشروعة، وكذلك وقف أو إلغاء عضوية الشخص المسيء، ولا يعني عدم حذفنا للتقييمات غير المشروعة مشاركة أو موافقة منا على النشر. ولا يجب أن يفهم حقنا في مراجعة التقييمات بأنه التزام قانوني علينا وعليك إبلاغنا في حالة وجود تقييمات غير مشروعة لاتخاذ الإجراء المناسب ضدها`
                  : ` The application "NKFEEK نكفيك" reserves the full right to review and delete any illegal evaluations, as well as suspend or cancel the membership of the abuser person, and our failure to delete illegal evaluations does not imply our participation or consent to post. Our right to review assessments should not be understood as a legal obligation on us and you must inform us in the event of illegal assessments to take appropriate action against them. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يحتفظ التطبيق بحقه في رفض نشر أي تقييمات في أي وقت إذا كانت تخالف الأحكام السابقة. `
                  : ` The application reserves the right to refuse to publish any evaluations at any time if they violate the previous provisions. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	في جميع الأحوال نحتفظ بكامل الحق في اللجوء إلى القضاء واقتضاء كافة التعويضات المناسبة إذا ترتب على التقييمات أضرارًا للتطبيق المملوك لنا، مع احتفاظ المضرور والمجتمع بحقه في العقوبات الجنائية`
                  : ` In all cases, we reserve the full right to resort to the judiciary and to seek all appropriate compensation if the evaluations result in damage to the application owned by us, while the injured party and society reserve their right to criminal sanctions.`}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "حقوق التأليف والنشر" : `Copyright`}</h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	التطبيق والأفكار المعبر عنها داخله، هي حقوق ملكية فكرية خالصة لنا، وأي تقليد أو اقتباس للتطبيق أو بعض خدماته بما في ذلك الأفكار والنصوص والرموز والبرمجيات) يعد انتهاكًا لحقوق التأليف والنشر الخاصة بناء وسنقوم معه باتخاذ كافة الإجراءات القانونية ضد مرتكب الانتهاكات المذكورة.`
                  : ` The application and the ideas expressed within it, are the exclusive intellectual property rights of us, and any imitation or quotation of the application or some of its services (including ideas, texts, symbols and software) is a violation of the copyright of Bina and we will take all legal action against the perpetrator of the aforementioned violations.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	جميع المحتويات المضمنة أو المتاحة ضمن خدمات تطبيق "نكفيك مثل النصوص والشعارات والصورمن خدماتنا هو ملك حصرلنكفيك" أو إتاحتها من قبل أيًا. وخاص بتطبيق "نكفيك" ومحمية بموجب قوانين حقوق الطبع والنشر السعودية والدولية وكذلك محمية بموجب الاتفاقيات الدولية السارية، مثل اتفاقية برن واتفاقية تربس. `
                  : ` All content included or available within the services of the application "NKFEEK نكفيك" such as texts, logos and images of our services is the exclusive property of "NKFEEK نكفيك" or made available by any. It is specific to the "NKFEEK نكفيك" application and is protected by Saudi and international copyright laws as well as protected by applicable international conventions, such as the Berne Convention and the TRIPS Convention.`}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "العلامات التجارية " : `Trademarks `}</h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? `نكفيك والشعارات المرتبطة به هي علامات تجارية و/أو علامات خدمة خاصة بنا. `
                  : ` "NKFEEK نكفيك" and associated logos are our trademarks and/or service marks.  `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	الصور والشعارات ورؤوس الصفحات وأيقونات الأزرار والنصوص والأسماء الخدمية هي علامات تجارية وتصميمات تجارية خاصة بـ تطبيق "نكفيك". `
                  : ` Images, logos, page headers, button icons, text and service names are trademarks and trade designs of the "NKFEEK نكفيك" application.  `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يحظر إعادة إنتاج العلامات أو التصميمات التجارية الخاصة بتطبيق "نكفيك في أي وسيلة إعلامية أو إعلانية دون إذن كتابي منا. `
                  : ` It is prohibited to reproduce the marks or commercial designs of the “NKFEEK نكفيك" application in any media or advertising without our written permission.  `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` لا يجوز استخدام العلامات والتصميمات التجارية الخاصة بتطبيق "نكفيك" في اتصال بأي منتج أو خدمة ليست تابعة لنا، حتى لا تتسبب في النيل من قدر ومصداقية تطبيق "نكفيك" أو النيل من عملائها. `
                  : ` It is not permissible to use the trademarks and designs of the "NKFEEK نكفيك" application in connection with any product or service that is not affiliated with us, so as not to undermine the value and credibility of the "NKFEEK نكفيك" application or harm its customers.  `}
              </li>
            </ul>

            <h4>
              {lang === "arabic" ? "التراخيص الممنوحة  " : `Licenses Granted `}
            </h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	بالتزامك بشروط الاستخدام أو أي شروط خدمة وكذلك بدفعك لأي رسوم مطبقة، يمنحك التطبيق ترخيصًا محدودًا وغير حصري، وغير قابل للتحويل وغير قابل للترخيص من الباطن للوصول إلى الاستخدام غير التجاري لخدمات تطبيق "مستر مندوب". `
                  : ` By your compliance with the Terms of Use or any Terms of Service as well as by paying any applicable fees, the Application grants you a limited, non-exclusive, non-transferable and non-sublicensable license to access the non-commercial use of the Services of the "Mr. Representative" application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا يشمل هذا الترخيص إعادة بيع أو أي استخدام تجاري لأي من خدماتنا أو محتوياتها، كما لا يشمل أي "والرسوم البيانية والتسجيلات الصوتية وأيقونات الأزرار والمحتويات الرقمية والمواد التي يتم إعادة تحميلها والبرمجيات وتجميع (البيانات هي ملك لتطبيق "نكفيك"، وهي محمية من قبل قوانين المملكة العربية السعودية وقوانين حقوق التأليف والنشر الدولية. `
                  : ` This license does not include the resale or any commercial use of any of our services or contents, nor does it include any "Graphics, audio recordings, button icons, digital content, reloaded materials, software and compilation (data is the property of the “NKFEEK نكفيك” application) and is protected by the laws of the Kingdom of Saudi Arabia and international copyright laws. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تجميع كافة البيانات المدرجة في خدمة "الشروط والأحكام" نسخ للمعلومات المتوفرة عن الحساب لصالح الغير، أو أي استخدام لوسائل استخراج البيانات أو أي استخدام لأدوات جمع واستخلاص البيانات المماثلة.`
                  : ` Compilation of all data included in the service “Terms & Conditions” Copies of the information available about the account for the benefit of third parties, or any use of data mining methods or any use of similar data collection and extraction tools.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا يجوز لك إعادة إنتاج أو عمل نسخة مطابقة لهذا التطبيق، أو نسخ أو بيع أو إعادة بيع أي جزء منه، أو استخدامه بصورة مغايرة في أغراض الاستغلال التجاري أو غير التجاري له دون الحصول على موافقة كتابية صريحة من تطبيق "نكفيك". `
                  : ` You may not reproduce or make an identical copy of this application, or copy, sell, resell any part of it, or use it differently for the purposes of commercial or non-commercial exploitation of it without the express written consent of the "NKFEEK نكفيك" application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا يجوز لك استخدام أي علامات وصفية أو أي "نص مخفي " آخر يستغل اسم "نكفيك" أو علاماتها التجارية بدون موافقة كتابية صريحة من التطبيق. `
                  : ` You may not use any meta tags or any other "hidden text" that exploits the name "NKFEEK نكفيك " or its trademarks without the express written consent of the application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` أنت توافق على عدم استخدام تطبيق "نكفيك" أو أي خدمة تقدم من خلاله بطريقة غير قانونية أو احتيالية أو معادية للمجتمع على النحو الذي نقدره. `
                  : ` You agree not to use the "NKFEEK نكفيك" application or any service provided through it in an illegal, fraudulent or anti-social manner as we value. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	أنت توافق على عدم إرسال أي رسائل تتسم بالتمييز العنصري أو تحتوي على سب وقذف أو ألفاظ نابية أو صور خليعة أو فاحشة، أو تتسم بوجه عام بأنها ذات "ذوقرديء". `
                  : ` You agree not to send any messages that are racially discriminatory, contain insults, slander, profanity, pornography or obscene, or are generally characterized as "bad taste". `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يحظر عليك كمستخدم نشر أي روابط خاصة بتطبيقات أو مواقع أخرى داخل التطبيق، أو تتضمن برمجيات خبيثة أو فيروسات أو تهدف إلى أي عملية احتيالية، أو أي من عمليات القرصنة الالكترونية. `
                  : ` As a user, you are prohibited from publishing any links to other applications or sites within the application, or that include malicious software or viruses, or aim at any fraudulent process, or any of the electronic piracy operations. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يحظر عليك التدخل بأي شكل من الأشكال في طريقة عمل التطبيق، أو سير العمل داخله، أو أن تعلن بأي شكل من الأشكال أنك تمثلنا على خلاف الحقيقة والواقع. `
                  : ` You are prohibited from interfering in any way in the way the application works, or the workflow within it, or to declare in any way that you represent us contrary to truth and reality. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يجوز لك استخدام خدماتنا بالشكل المسموح به قانونا فقط، ووفقًا لشروط هذه الاتفاقية. `
                  : ` You may use our services only in the form permitted by law, and in accordance with the terms of this agreement.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تنتهي التراخيص الممنوحة من قبلنا إذا لم تلتزم بشروط الاستخدام هذه أو أي شروط خدمة أخرى`
                  : ` The licenses granted by us shall expire if you do not comply with these Terms of Use or any other Terms of Service. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	جميع الحقوق غير الممنوحة لك صراحة في شروط الاستخدام هذه أو أي شروط خدمة أخرى يحتفظ بها تطبيق "نكفيك".`
                  : ` All rights not expressly granted to you in these Terms of Use or any other terms of service held by the "NKFEEK نكفيك" application. `}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "الاتصالات" : `Communications`}</h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? `نت توافق على تلقي الاتصالات منا، وسوف نتواصل معك عن طريق رقم الجوال وعن طريق نشر إشعارات عبر التطبيق أو من خلال خدمات تطبيق "نكفيك" القانونية. `
                  : ` 	You agree to receive communications from us, and we will communicate with you by mobile number and by posting notifications through the application or through the services of the "NKFEEK نكفيك" application legal. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يتطلب تطبيق "نكفيك" موافقتك أثناء عملية التسجيل على قيامنا بإرسال رسائل على هاتفك الجوال أو عبر التطبيق لأغراض ترويجية، وذلك لإعلامك بأي تغييرات أو ميزات أو أنشطة جديدة تضاف إلى التطبيق. `
                  : ` 	The "NKFEEK نكفيك" application requires your consent during the registration process to us sending messages on your mobile phone or through the application for promotional purposes, in order to notify you of any changes, features or new activities added to the application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` إذا قررت في أي وقت أنك لا ترغب في تلقي رسائل ترويجية، يمكنك تعطيل تلقي هذه الرسائل عن طريق مراسلتنا، ولكن في هذه الحالة لا نضمن تمتعك بخدماتنا بشكل كامل.`
                  : ` If you decide at any time that you do not wish to receive promotional messages, you can disable receiving these messages by contacting us, but in this case we do not guarantee that you will fully enjoy our services.`}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "الإشعارات  والاذونات"
                : `Notices and Permissions`}
            </h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` أي إشعارات تود إرسالها لتطبيق "نكفيك" يجب أن يتم إرسالها عبر الوسائل المحددة داخل التطبيق، ولا يعتد بأي إشعارات يتم إرسالها خارج التطبيق. `
                  : ` Any notifications you wish to send to the "NKFEEK نكفيك" application must be sent through the specified means within the application, and any notifications sent outside the application shall not be considered. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	أي إشعارات يود تطبيق "نكفيك" إرسالها إليك،`
                  : ` Any notifications that the "NKFEEK نكفيك" application would like to send you.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يستخدم التطبيق خاصية تحديد الموقع وبذلك انت تقر بانه لامانع لديك سواء من العميل او مزود الخدمة وحتى تكون الخدمة بشكل افضل لطرفين`
                  : ` The application uses the location feature, so you acknowledge that you do not mind, whether from the customer or the service provider, so that the service is better for two parties.`}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "الضرائب والرسوم" : `Taxes and Fees`}</h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	يلتزم المستخدم بسداد كافة الضرائب الحكومية على اختلاف أنواعها التي تفرض على النشاط الذي يمارسه من خلال التطبيق لشروط والأحكام`
                  : ` The user is obligated to pay all government taxes of all kinds imposed on the activity practiced through the application Terms & Conditions`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يلتزم المستخدم بدفع رسوم شركات الاتصالات بهدف الحصول على البيانات الخاصة بالوصول إلى شبكة  الانترنت`
                  : ` The user is obligated to pay the fees of telecommunications companies in order to obtain data related to access to the network Internet. `}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "تحويل الحقوق والالتزامات "
                : `Transfer of Rights and Obligations `}
            </h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` يحق لتطبيق "نكفيك" تحويل كافة حقوقه الواردة في هذه الاتفاقية للغير دون أي اعتراض من المستخدم، ويلتزم الجميع بتنفيذ كافة التزاماتهم الواردة في هذه الاتفاقية قبل المحال إليه فور إخطارهم بالحوالة، ويحق للمحال إليه اتخاذ كافة الإجراءات القانونية قبل المتقاعس عن تنفيذ التزاماته بموجب هذه الاتفاقية`
                  : ` 	The application of "NKFEEK نكفيك" has the right to transfer all its rights contained in this agreement to third parties without any objection from the user, and everyone is obligated to implement all their obligations contained in this agreement before the assignee immediately upon notification of the transfer, and the assignee has the right to take all legal action before failing to perform his obligations under this agreement.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا يجوز لك التنازل عن التزاماتك وحقوقك بموجب هذه الاتفاقية، أو أن تعهد بإدارة حسابك بالتطبيق إلى طرف ثالث إلا بعد الحصول على موافقة خطية منا.`
                  : ` 	You may not assign your obligations and rights under this agreement, or entrust the management of your account in the application to a third party except after obtaining our written consent. `}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "التعديلات" : `Amendments`}</h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	يجوز لنا تعديل أو تحديث شروط وأحكام هذه الاتفاقية الخاصة باستخدام التطبيق بدون إرسال إخطار إليك بذلك لذا عليك مراجعة هذه الاتفاقية بشكل دوري. `
                  : ` 	We may amend or update the terms and conditions of this agreement for the use of the application without sending you notice, so you should review this agreement periodically. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا يجوز لك تغيير أى بنود من هذا الاتفاق أو تعديلها أو استبدالها بدون موافقة مكتوبة من تطبيق "نكفيك".`
                  : ` You may not change, amend or replace any terms of this Agreement without the written consent of the "NKFEEK نكفيك" application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	أنت توافق على الالتزام بكافة الشروط والبنود الإضافية التي ستتاح لك والتى تتعلق باستخدام أى من الخدمات المتاحة عبر التطبيق، ويتم دمج هذه الشروط والبنود الإضافية إلى هذه الاتفاقية`
                  : ` You agree to be bound by all additional terms and conditions that will be available to you related to the use of any of the services available through the application, and these additional terms and conditions are incorporated into this agreement.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يجوز لنا فى أى وقت إجراء أية تعديلات أو تحسينات نراها ضرورية على التطبيق لزيادة فاعليته، ويلتزم المستخدم بأية توجيهات أو تعليمات يقدمها التطبيق إليه في هذا الخصوص`
                  : ` 	We may at any time make any modifications or improvements we deem necessary to the application to increase its effectiveness, and the user is bound by any directives or instructions provided by the application to him in this regard.`}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "إلغاء الاتفاق "
                : `Cancellation of the Agreement `}
            </h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	يحق لنا فى أى وقت إلغاء كل أو بعض الخدمات التي نقدمها، كما يحق لنا فى أى وقت إلغاء الشروط الأحكام استبدالها بشروط واحكام اخرى أ`
                  : ` We have the right at any time to cancel all or some of the services we provide, and we have the right at any time to cancel the terms and conditions and replace them with other terms and conditions.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تعد هذه الاتفاقية مفسوخة من تلقاء ذاتها في حالة إخلالك بحقوقنا المالية أو العمولات المستحقة لنا، أو التسبب في مشكلات قانونية لنا أو لأطراف هذه الاتفاقية أو للغير، أو في حالة إخلالك بأي بند أو فقرة من شروط وأحكام هذه الاتفاقية، أو في حالة : توثيق معلوماتك، أو توقف نشاط التطبيق. وفي جميع الأحوال السابقة يحق لنا الرجوع عليك قضائيًا بموجب هذه الاتفاقية إذا كان سبب الفسخ يرجع لإخلالك بأحكامها. `
                  : `This agreement is terminated on its own initiative in the event that you breach our financial rights or commissions due to us, or cause legal problems to us or the parties to this agreement or to third parties, or in the event that you breach any clause or paragraph of the terms and conditions of this agreement, or in the event of documenting your information, or stopping the activity of the application. In all the above cases, we have the right to recourse against you under this agreement if the reason for the termination is due to your breach of its provisions. `}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "المسئولية القانونية لأطراف الاتفاقية "
                : `Legal Liability of the Parties to the Agreement `}
            </h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` أنت توافق صراحة على أنك تستخدم تطبيق "نكفيك" على مسئوليتك الشخصية. `
                  : ` You expressly agree that you use the "NKFEEK نكفيك" application at your own risk. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يجب عليك الالتزام بجميع القوانين واللوائح المعمول بها داخل المملكة العربية السعودية فيما يتعلق باستخدامك للتطبيق، وتتحمل كافة المسئوليات الناشئة في حالة إخلالك بهذه القوانين أو اللوائح، كما تلتزم بجميع الشروط والأحكام المنصوص عليها في هذه الاتفاقية. `
                  : ` You must comply with all laws and regulations in force within the Kingdom of Saudi Arabia regarding your use of the application, and bear all responsibilities arising in the event that you breach these laws or regulations, and also abide by all the terms and conditions stipulated in this agreement. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` أنت مسئول عن المحافظة على استخدام التطبيق بكل جدية ومصداقية وتكون ملزمًا بالتعويض عن أي خسائر أو أضرار قد تلحق بالتطبيق نتيجة أي استخدام غير شرعي أو غير مفوض من قبلنا. `
                  : ` You are responsible for maintaining the use of the application with all seriousness and credibility and you are obligated to compensate for any losses or damages that may be caused to the application as a result of any illegal or unauthorized use by us. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يزودنا المستخدمون بمعلوماتهم وأرقام الجوال تحت مسئوليتهم الشخصية، وبالتالي فإنهم يتحملون المسئولية المدنية والجنائية في حالة كون هذه الأرقام مسروقة أو غير مسجلة بأسمائهم أو أنها تخص أشخاص آخرين. `
                  : ` Users provide us with their information and mobile numbers under their personal responsibility, and therefore they bear civil and criminal liability in the event that these numbers are stolen or not registered in their names or belong to other people. `}
              </li>
              <li>
                {lang === "arabic"
                  ? `نت تقر بمسئوليتك القانونية الكاملة في حالة إخلالك بكافة البنود المنصوص عليها في هذه الاتفاقية، ويحق لنا مع هذا الإخلال اتخاذ كافة الإجراءات ضدك، بما في ذلك إيقاف أو إلغاء عضويتك أو اللجوء إلى القضاء. `
                  : ` You acknowledge your full legal responsibility in the event that you breach all the terms stipulated in this agreement, and with this breach we have the right to take all measures against you, including suspending or canceling your membership or resorting to the judiciary. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	يكون على المتضرر الذي أصابه ضرر نتيجة إخلال أي طرف من أطراف هذه الاتفاقية أن يقوم باتخاذ كافة الإجراءات القانونية اللازمة تجاه مرتكب الفعل غير المشروع دون الرجوع إلينا أو علينا، ويقوم المتضرر بإبلاغنا بالمخالفة فقط لاتخاذ إجراء داخل التطبيق إما بوقف العضوية أو حظرها بشكل دائم.`
                  : ` A victim who has suffered damage as a result of a breach by any of the Parties to this Agreement shall take all the necessary legal procedures against the perpetrator of the illegal act without referring to us or us, and the affected person informs us of the violation only to take action within the application by either suspending membership or banning it permanently.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لا تنال العقوبات التي يقوم تطبيق "نكفيك" بفرضها على أطراف هذه الاتفاقية من حق كل طرف من اتخاذ كافة الإجراءات القانونية التي يراها مناسبة وفقًا لتقديره دون الرجوع علينا بأي شكل من الأشكال ودون أدنى مسئولية على التطبيق`
                  : ` 	The penalties imposed by the application of "NKFEEK نكفيك" on the parties to this agreement do not affect the right of each party to take all legal measures it deems appropriate at its discretion without recourse to us in any way and without any responsibility for the application.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تطبيق "نكفيك" لا يضمن اتخاذه إجراءات ضد كل الانتهاكات التي قد تحدث لاتفاقية الاستخدام هذه، ولا يعني عدم اتخاذنا للإجراءات القانونية في أي من حالات الانتهاك تنازلاً عن حقنا في اتخاذ هذه الإجراءات في أي وقت نراه مناسبًا.`
                  : ` The application of "NKFEEK نكفيك" does not guarantee that it will take action against all violations that may occur to this usage agreement, and our failure to take legal action in any of the cases of violation does not mean a waiver of our right to take such actions at any time we deem appropriate.`}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "حدود مسئوليتنا "
                : `Limits of Our Responsibility `}
            </h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? `قدم خدماتنا "كما هي متوفرة"، دون أي تعهدات أو ضمانات من أي نوع، سواء صريحة أو ضمنية، تتعلق باستخدام هذا التطبيق أو محتواه أو الخدمات المقدمة عليه.`
                  : ` 	We provide our services "as available", without any representations or warranties of any kind, whether express or implied, related to the use of this application, its content or the services provided on it.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تطبيق "نكفيك" لا يقدم أي ضمانات بخلو هذا التطبيق أو الخوادم الخاصة به أو الرسائل المرسلة من الفيروسات أو غيرها من المكونات الضارة. `
                  : ` The application "NKFEEK نكفيك" does not provide any guarantees that this application, its servers or messages sent are free from viruses or other harmful components.  `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` لا يقدم تطبيق "نكفيك" أي ضمان ولا يتحمل أي مسئولية فيما يتعلق بحداثة وشيوع ودقة وجودة المعلومات التي يتلقاها المستخدم أو يتوصل إليها عبر التطبيق`
                  : `  The application "NKFEEK نكفيك" does not provide any warranty and does not assume any responsibility regarding the timeliness, popularity, accuracy and quality of the information received by the user or accessed through the application. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تقع مسئولية استخدام أو الاعتماد على المعلومات التي يتلقاها أو يتوصل إليها المستخدم من خلال خدمات تطبيق "نكفيك" على عاتق هذا المستخدم بشكل كامل.`
                  : ` The responsibility for using or relying on the information received or accessed by the user through the services of the "NKFEEK نكفيك" application lies entirely with this user.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تقر بأن تطبيق "مستر مندوب" عبارة عن خدمة قائمة على الانترنت، وأنه على الرغم من بذلنا أقصى جهد للحفاظ على المعلومات وإبقائها امنة، إلا أننا لا نستطيع أن نضمن أن تكون المعلومات التي يتلقاها المستخدم أو يقوم بيثها أثناء استخدامه للتطبيق آمنة في كل الأوقات. `
                  : ` You acknowledge that the "Mr. Representative" application is an Internet-based service, and that although we have made every effort to keep the information secure, we cannot guarantee that the information received or transmitted by the user while using the application will be secure at all times.  `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	تطبيق "نكفيك" لا يستطيع أن يؤكد أن كل مستخدم للتطبيق هو فعلاً الشخص الذي يدعيه.`
                  : ` The “NKFEEK نكفيك” application cannot confirm that each user of the application is indeed the person he claims. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	قد نقوم في أي وقت بتعديل أو وقف أو قطع خدمات تطبيق "نكفيك" بدون إرسال إخطار إليك بذلك، كما قد نقوم بوقف استخدامك لخدماتنا إذا قمت بانتهاك هذه الشروط والبنود أو إذا أسأت استخدام هذه الخدمات من وجهة نظرنا`
                  : ` We may at any time modify, suspend or discontinue the services of the "NKFEEK نكفيك" application without giving you notice, and we may stop your use of our services if you violate these terms and conditions or if you misuse these services from our point of view. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	مع عدم الإخلال بحقوقه الأخرى فان لتطبيق "نكفيك الحق في وقف أو إلغاء عضويتك أو وصولك إلى التطبيق في أي وقت وبدون إنذار ولأي سبب، ودون تحديد، ويمكنه إلغاء اتفاقية المستخدم هذه.`
                  : ` 	Without prejudice to its other rights, the application "NKFEEK نكفيك" has the right to suspend or cancel your membership or access to the application at any time and without notice and for any reason, without limitation, and it can cancel this user agreement.`}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "التعويضات " : `Compensations`}</h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	لن يكون التطبيق مسئولاً في جميع الأحوال عن أي خسارة مباشرة أو غير مباشرة أو تبعية أو خسارة في الأرباح والشهرة أو ضرر أيًا كان نتيجة الإفصاح عن اسم المستخدم الخاص بك و/أو كلمة المرور.`
                  : ` The application will not be responsible in all cases for any direct, indirect or consequential loss, loss of profits and goodwill, or damage whatsoever as a result of disclosing your username and/or password.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` تطبيق "نكفيك غير مسئول قانونًا عن الخسائر أو الأضرار أيًا كان نوعها التي قد تنشأ عن استخدام هذا التطبيق بما في ذلك على سبيل المثال لا الحصر الأضرار المباشرة والغير مباشرة المباشرة وغير المباشرة. `
                  : ` The application " NKFEEK نكفيك " is not legally responsible for losses or damages of any kind that may arise from the use of this application, including but not limited to direct and indirect damages direct and indirect. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	لن يكون تطبيق "نكفيك" ومسئوليه وموظفيه مسئولين قانونًا تجاهك أو تجاه أي طرف آخر عن أي خسارة مباشرة أو غير مباشرة أو عن أي تكلفة أخرى قد تنشأ من أو فيما يتصل بتنفيذ اتفاقية الاستخدام، أو فيما يتصل بتقديم خدماتنا.`
                  : ` The application "NKFEEK نكفيك" and its officers and employees shall not be legally liable to you or any third party for any direct or indirect loss or for any other cost that may arise out of or in connection with the implementation of the usage agreement, or in connection with the provision of our services.`}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	على المستخدم أن يحمي تطبيق "نكفيك" وموظفيه وأن يدافع عنهم ويعوضهم عن أية خسائر ناتجة عن أية دعوى أو مطالبة تتعلق بالتطبيق أو ناتجة عن عمل أو إهمال من قبل المستخدم أو ممثليه أو وكلائه`
                  : ` The user shall protect, defend and indemnify the application "NKFEEK نكفيك" and its employees from any losses resulting from any claim or claim related to the application or resulting from the act or negligence of the user, his representatives or agents. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	أنت توافق على تعويض تطبيق "مستر مندوب" وإبراء مسئوليته والدفاع عنه ضد كافة الدعاوى والمطالبات والمطالب والخسائر والنفقات بما فيها أتعاب المحامي) التي قد يرفعها أو يطالب بها الغير نتيجة استخدامك للتطبيق، أو بسبب قيامك بانتهاك هذه الشروط والبنود أو بالإخلال بحقوق المستخدمين الآخرين`
                  : ` You agree to indemnify the application of "Mr. Mandoob" and discharge its responsibility and defense against all claims, claims, demands, losses and expenses (including attorney's fees) that may be filed by or claimed by third parties as a result of your use of the application, or because of your violation of these terms and conditions or violation of the rights of other users.`}
              </li>
            </ul>

            <h4>
              {lang === "arabic"
                ? "القانون واجب التطبيق والمحاكم المختصة"
                : `Applicable Law and Courts Competent`}
            </h4>
            <ul>
              <li>
                {lang === "arabic"
                  ? ` يحكم هذه الاتفاقية القوانين المعمول بها والسارية المفعول داخل المملكة العربية السعودية`
                  : ` This Agreement shall be governed by the laws in force and in force within the Kingdom of Saudi Arabia. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` يختص القضاء داخل المملكة العربية السعودية بأي نزاع قد ينشأ عن تفسير أو تنفيذ أحكام هذه الاتفاقية`
                  : ` 	The judiciary within the Kingdom of Saudi Arabia shall have jurisdiction over any dispute that may arise from the interpretation or implementation of the provisions of this Convention. `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` 	في حال أصبح أي حكم من أحكام هذه الاتفاقية غير ساري أو غير قانوني أو غير قابل للتنفيذ، فان قانونية وقابلية تنفيذ الأحكام الأخرى لن تتأثر بأي طريقة كانت ستة وعشرون اللغة (1) يعتبر النص العربي لهذه الاتفاقية هو النص المعتمد لأغراض تفسير وتطبيق `
                  : ` In the event that any provision of this Agreement becomes invalid, illegal or unenforceable, the legality and enforceability of the other provisions shall not be affected in any way whatsoever.`}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "اللغات" : `Languages `}</h4>

            <ul>
              <li>
                {lang === "arabic"
                  ? ` 	يعتبر النص العربي لهذه الاتفاقية هو النص المعتمد لأغراض تفسير وتطبيق بنودها وشروطها.`
                  : ` The Arabic text of this Agreement shall be deemed to be the text adopted for the purposes of interpretation and application of its terms and conditions.  `}
              </li>
              <li>
                {lang === "arabic"
                  ? ` في حالة وجود تعارض بين النص العربي والنص المترجم لهذه الاتفاقية، فمن المتفق عليه في هذه الحالة تطبيق ما جاء باللغة العربية بذلك الحكم.`
                  : ` In the event of a conflict between the Arabic text and the translated text of this Convention, it is agreed in this case that the Arabic language shall apply that provision. `}
              </li>
            </ul>

            <h4>{lang === "arabic" ? "إعلام" : `Notification `}</h4>
            <p>
              {lang === "arabic"
                ? `أتعهد بالرجوع إلى موقع هيئة الاتصالات وتقنية المعلومات (https://www.citc.gov.sa/ar/Pages/default.aspx) لمعرفة الشروط والضوابط ومتابعة كل ما هو جديد فيه.`
                : `I pledge to refer to the Communications and Information Technology Commission website (https://www.citc.gov.sa/ar/Pages/default.aspx) to know the conditions and controls and follow up on everything new in it.`}
            </p>
          </div>
          {/* <div className="col text-center">
            <img src={SideImage} alt="" width={400} />
          </div> */}
        </div>
      </div>
      <footer className="footer-distributed bg-footer ">
        <div className="container">
          <div className="footer-left">
            <h3>
              <a href="/" className="text-decoration-none">
                <img src={Logo} className="logo-icon" alt="logo" />
                <span>NKFEEK نكفيك</span>
              </a>
            </h3>
            <p className="footer-links">
              <a href="/#Services" className="link-1">
                {lang === "arabic" ? "مقدم الخدمات" : "Services Requester"}
              </a>
              {/* <a href="/#Passenger">Passenger</a> */}
              <a href="/#Contact">{lang === "arabic" ? "اتصال" : "Contact"}</a>
            </p>
            <div className="footer-company-name">
              <div style={{ display: "inline-block" }}> NKFEEK &nbsp;</div>
              <div style={{ display: "inline-block" }}>نكفيك</div>
              <div style={{ display: "inline-block" }}>&nbsp; © 2023 </div>
            </div>
          </div>

          <div className="footer-center">
            {/* <div>
              <IoMdCall className='fs-4 text-light pe-2' />
              <p><a href="tel:+1234567890">+1234567890</a></p>
            </div>
            <div>
              <IoMdMailOpen className='fs-4 text-light pe-2' />
              <p><a href="mailto:support@nakfeek.com">support@nakfeek.com</a></p>
            </div> */}
          </div>

          <div className="footer-right">
            <div className="btn-download">
              <button className="btn px-0">
                <a
                  href="https://apps.apple.com/us/app/nkfeek-%D9%86%D9%83%D9%81%D9%8A%D9%83/id6472803579"
                  target="_blank"
                  className="market-btn apple-btn "
                  role="button"
                >
                  <span className="market-button-subtitle">
                    {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                  </span>
                  <span className="market-button-title">
                    {" "}
                    {lang === "arabic" ? "متجر التطبيقات" : "App Store"}
                  </span>
                </a>
              </button>
              <br />
              <button className="btn px-0">
                <a
                  href="https://play.google.com/store/apps/details?id=com.aw.taxiapp.sa"
                  target="_blank"
                  rel="noopener"
                  className="market-btn google-btn"
                  role="button"
                >
                  <span className="market-button-subtitle">
                    {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                  </span>
                  <span className="market-button-title">
                    {lang === "arabic" ? "تطبيقات جوجل" : "Google Play"}
                  </span>
                </a>
              </button>
            </div>
            <div className="footer-icons">
              {/* <a href="https://www.facebook.com/"><RiFacebookFill /></a>
              <a href="https://twitter.com/login"><BsTwitter /></a>
              <a href="https://in.linkedin.com/"><FaLinkedinIn /></a>
              <a href="https://www.google.com/intl/en_in/gmail/about/"><BsGoogle /></a> */}
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid bg-footer">
        <div className="container ">
          <div className="col d-flex flex-row  py-3 privacy ">
            <Link to="/privacy" className="text-decoration-none">
              <p className=" mb-0 text-light me-3 p-content">
                {" "}
                {lang === "arabic" ? "سياسة الخصوصية" : "Privacy Policy"}
              </p>
            </Link>
            <Link to="/terms" className="text-decoration-none">
              {" "}
              <p className="mb-0 text-light p-content">
                {" "}
                {lang === "arabic" ? "البنود و الظروف" : "Terms & Conditions"}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
