import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Site from './layouts/WebsiteLayout/Site';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import TermsPage from './pages/Terms/TermsPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <><Routes>
    <Route exact path='/' element={<Site />} />
    <Route exact path='/privacy' element={<PrivacyPolicy />} />
    <Route exact path='/terms' element={<TermsPage />} />
  </Routes><ToastContainer theme='light' autoClose={6000} /></>
    
    
  );
}

export default App;
