import React, { useEffect, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import About from "../../pages/about/About";
import Contact from "../../pages/contact/Contact";
import DriverSection from "../../pages/driverSection/DriverSection";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./style.css";
import Passenger from "../../pages/passengerSection/Passenger";
import { ToastContainer } from "react-toastify";

const Site = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <>  
      <Navbar />
      <About />
      <DriverSection />
      {/* <Passenger /> */}
      <Contact />
      <Footer /> 
      <button
        className="btn-up"
        style={{ display: visible ? "inline" : "none" }}
      >
        <FaArrowCircleUp className="button-up fs-1" onClick={scrollToTop} />
      </button>
    </>
  );
};

export default Site;
