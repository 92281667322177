import React from "react";
import Navbar from "../../layouts/navbar/Navbar";
import "./privacy.css";
import Logo from "../../assests/svg/taxi-another.svg";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  // const queryParams = new URLSearchParams(window.location.search);
  // const lang = queryParams.get("lang");
  const lang = localStorage.getItem("lang");

  return (
    <div>
      <Navbar />
      <div className="container privacy mt-5">
        <div className="row my-4">
          <div className="col">
            <h4 className="mt-4 ">
              {lang === "arabic" ? "سياسة الخصوصية" : "Privacy Policy "}
            </h4>
            <div className="head-policy"></div>
            <p className="privacy-para mt-4">
              {lang === "arabic"
                ? `نشكرك على زيارة موقع "NKFEEK نكفيك" ("نحن" أو "نا" أو "نا"). خصوصيتك مهمة بالنسبة لنا. توضح سياسة الخصوصية هذه كيف نجمع معلوماتك الشخصية ونستخدمها ونحميها. باستخدام موقعنا ، فإنك توافق على الممارسات الموضحة في هذه السياسة.`
                : `Thank you for visiting NKFEEK نكفيك  ("we," "our," or "us") website. Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information. By using our website, you consent to the practices described in this policy.`}
            </p>
          </div>
        </div>
        <div className="row my-4">
          <div className="">
            <div className="col">
              <h4 className="">
                {lang === "arabic"
                  ? `المعلومات التي نجمعها`
                  : "Information We Collect"}{" "}
              </h4>
              <p className="privacy-para ">
                {lang === "arabic"
                  ? "قد نجمع أنواعًا مختلفة من المعلومات عندما تزور موقعنا ، بما في ذلك:"
                  : "We may collect various types of information when you visit our website, including:"}
              </p>
            </div>
          </div>
          <div className="">
            <p className="privacy-para ">
              {" "}
              {lang === "arabic" ? (
                <p>
                  {" "}
                  <span className="fw-bold  ms-2">معلومات شخصية: </span>قد نقوم
                  بجمع معلومات شخصية مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك
                  وعنوانك البريدي عندما تقدمها لنا طوعًا من خلال نماذج الاتصال
                  الخاصة بنا أو وسائل أخرى.
                </p>
              ) : (
                <p>
                  {" "}
                  <span className="fw-bold me-2">Personal Information:</span> We
                  may collect personal information such as your name, email
                  address, phone number, and postal address when you voluntarily
                  provide it to us through our contact forms or other means.
                </p>
              )}{" "}
            </p>
          </div>
          <div className="">
            <p className="privacy-para ">
              {" "}
              {lang === "arabic" ? (
                <p>
                  {" "}
                  <span className="fw-bold ms-2">معلومات الاستخدام: </span> قد
                  نقوم بجمع معلومات حول كيفية استخدامك لموقعنا الإلكتروني، مثل
                  الصفحات التي تزورها، والروابط التي تنقر عليها، وتفاعلاتك مع
                  المحتوى الخاص بنا. تساعدنا هذه المعلومات في تحسين موقعنا على
                  الويب وتوفير تجربة مستخدم أفضل.
                </p>
              ) : (
                <p>
                  {" "}
                  <span className="fw-bold me-2">Usage Information: </span> We
                  may collect information about how you use our website, such as
                  the pages you visit, the links you click, and your
                  interactions with our content. This information helps us
                  improve our website and provide a better user experience.
                </p>
              )}{" "}
            </p>
          </div>
          <div className="">
            <p className="privacy-para ">
              {" "}
              {lang === "arabic" ? (
                <p>
                  {" "}
                  <span className="fw-bold ms-2">
                    ملفات تعريف الارتباط وتقنيات التتبع:{" "}
                  </span>{" "}
                  مثل العديد من مواقع الويب، نستخدم ملفات تعريف الارتباط وتقنيات
                  التتبع الأخرى لجمع معلومات حول سلوك التصفح الخاص بك وتفضيلاتك.
                  تُستخدم هذه المعلومات لتحليل حركة مرور موقع الويب ، وتحسين
                  تجربة المستخدم ، وتخصيص المحتوى.{" "}
                </p>
              ) : (
                <p>
                  {" "}
                  <span className="fw-bold me-2">
                    Cookies and Tracking Technologies:{" "}
                  </span>
                  Like many websites, we use cookies and other tracking
                  technologies to collect information about your browsing
                  behaviour and preferences. This information is used to analyse
                  website traffic, enhance user experience, and personalize
                  content.
                </p>
              )}{" "}
            </p>
          </div>
          <div className="">
            <p className="privacy-para ">
              {" "}
              {lang === "arabic" ? (
                <p>
                  {" "}
                  <span className="fw-bold ms-2">
                  خدمة الموقع:{" "}
                  </span>{" "}
                  نقوم بجمع بيانات الموقع التقريبية والدقيقة لتعزيز تجربة المستخدم وضمان تقديم خدمات دقيقة تعتمد على الموقع عند تتبع الموقع الحالي المباشر للعميل. تتيح لنا هذه المعلومات تحسين التوجيه وتقدير أوقات الوصول بشكل أكثر دقة وتحسين جودة الخدمة بشكل عام. كن مطمئنًا، فنحن نعطي الأولوية لأمان وسرية بيانات موقعك، مع الالتزام بمعايير الخصوصية الصارمة وعدم مشاركتها مطلقًا مع أطراف غير مصرح لها.{" "}
                </p>
              ) : (
                <p>
                  {" "}
                  <span className="fw-bold me-2">
                  Locations Service:{" "}
                  </span>
                  We collect both <span className="fw-bold">coarse</span> and <span className="fw-bold">fine</span> location data to enhance user experience and ensure accurate location-based services when tracking the customer's live current location. This information allows us to optimize routing, estimate arrival times more precisely, and improve overall service quality. Rest assured, we prioritize the security and confidentiality of your location data, adhering to strict privacy standards and never sharing it with unauthorized parties.
                </p>
              )}{" "}
            </p>
          </div>
        </div>

        <div className="row my-4">
          <div className="col">
            <h4 className="">
              {lang === "arabic"
                ? "كيف نستخدم معلوماتك"
                : "How We Use Your Information"}
            </h4>
            <p className="">
              {" "}
              {lang === "arabic"
                ? "قد نستخدم المعلومات التي نجمعها لأغراض مختلفة ، بما في ذلك:"
                : "We may use the information we collect for various purposes, including:"}
            </p>
            <ul className="privacy-para mb-0">
              <li>
                {" "}
                {lang === "arabic"
                  ? "الرد على استفساراتك وتقديم دعم العملاء."
                  : "Responding to your inquiries and providing customer support."}
              </li>
              <li>
                {" "}
                {lang === "arabic"
                  ? `إرسال التحديثات والنشرات الإخبارية والمواد الترويجية إليك إذا كنت قد اخترت ذلك.`
                  : "Sending you updates, newsletters, and promotional materials if you have opted in."}
              </li>
              <li>
                {" "}
                {lang === "arabic"
                  ? `تحليل استخدام موقع الويب لتحسين المحتوى والخدمات التي نقدمها.`
                  : "Analysing website usage to improve our content and services."}
              </li>
              <li>
                {" "}
                {lang === "arabic"
                  ? `	إضفاء الطابع الشخصي على تجربتك على موقعنا`
                  : "Personalizing your experience on our website."}
              </li>
              <li>
                {" "}
                {lang === "arabic"
                  ? `الامتثال للالتزامات القانونية وحماية حقوقنا.`
                  : "Complying with legal obligations and protecting our rights."}
              </li>
            </ul>
          </div>
        </div>

        <div className="row my-4">
          <div className="col">
            <h4 className="">
              {lang === "arabic"
                ? `تبادل المعلومات الخاصة بك`
                : "Sharing Your Information"}{" "}
            </h4>
            <p className="privacy-para ">
              {lang === "arabic"
                ? "نحن لا نبيع أو نتاجر أو نؤجر معلوماتك الشخصية لأطراف ثالثة. ومع ذلك ، قد نشارك معلوماتك في الحالات التالية:"
                : "We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following cases:"}{" "}
            </p>
            <ul className="privacy-para mb-0">
              <li>
                {" "}
                {lang === "arabic"
                  ? "	مع مزودي الخدمة الموثوق بهم الذين يساعدوننا في تشغيل موقعنا الإلكتروني وتقديم الخدمات لك."
                  : "With our trusted service providers who assist us in operating our website and providing services to you."}
              </li>
              <li>
                {" "}
                {lang === "arabic"
                  ? "	عندما يقتضي القانون أو لحماية حقوقنا وسلامتنا وسلامة الآخرين."
                  : "When required by law or to protect our rights, safety, and the safety of others."}
              </li>
              <li>
                {" "}
                {lang === "arabic"
                  ? "	فيما يتعلق بالبيع أو الاندماج أو الاستحواذ على كل أو جزء من شركتنا."
                  : "In connection with the sale, merger, or acquisition of all or a portion of our company."}
              </li>
            </ul>
          </div>
        </div>

        <div className="row my-4">
          <div className="col">
            <h4 className="">
              {lang === "arabic" ? "اختياراتك " : "Your Choices"}{" "}
            </h4>
            <p className="privacy-para ">
              {lang === "arabic" ? "لديك الحق في:" : "You have the right to:"}{" "}
            </p>
            <ul className="privacy-para">
              <li>
                {" "}
                {lang === "arabic"
                  ? `	إلغاء الاشتراك في تلقي رسائل البريد الإلكتروني الترويجية باتباع الإرشادات الواردة في رسائل البريد الإلكتروني.`
                  : "Opt-out of receiving promotional emails by following the instructions provided in the emails."}
              </li>
              <li>
                {" "}
                {lang === "arabic"
                  ? `	قم بتعطيل ملفات تعريف الارتباط في متصفح الويب الخاص بك ، على الرغم من أن ذلك قد يؤثر على تجربة التصفح لديك.`
                  : "Disable cookies in your web browser, although this may affect your browsing experience."}
              </li>
            </ul>
          </div>
        </div>

        <div className="row my-4">
          <div className="col">
            <h4 className="">{lang === "arabic" ? `حماية` : "Security"} </h4>
            <p className="privacy-para ">
              {lang === "arabic"
                ? `نتخذ تدابير معقولة لحماية المعلومات التي تقدمها لنا من الوصول غير المصرح به أو الفقد أو سوء الاستخدام أو التغيير. ومع ذلك ، لا يوجد نقل للبيانات عبر الإنترنت آمن بنسبة 100٪ ، ولا يمكننا ضمان أمان معلوماتك.`
                : "We take reasonable measures to protect the information you provide to us from unauthorized access, loss, misuse, or alteration. However, no data transmission over the internet is 100% secure, and we cannot guarantee the security of your information."}{" "}
            </p>
          </div>
        </div>

        <div className="row my-4">
          <div className="col">
            <h4 className="">
              {lang === "arabic"
                ? `روابط لمواقع أخرى`
                : "Links to Other Websites"}{" "}
            </h4>
            <p className="privacy-para ">
              {lang === "arabic"
                ? `قد يحتوي موقعنا على روابط لمواقع طرف ثالث. نحن لسنا مسؤولين عن المحتوى أو ممارسات الخصوصية لهذه المواقع. نحن نشجعك على مراجعة سياسات الخصوصية الخاصة بهم قبل تقديم أي معلومات شخصية.`
                : "Our website may contain links to third-party websites. We are not responsible for the content or privacy practices of these websites. We encourage you to review their privacy policies before providing any personal information."}{" "}
            </p>
          </div>
        </div>

        <div className="row my-4">
          <div className="col">
            <h4 className="">
              {lang === "arabic"
                ? `التغييرات على سياسة الخصوصية هذه`
                : "Changes to This Privacy Policy"}{" "}
            </h4>
            <p className="privacy-para ">
              {lang === "arabic"
                ? `قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. عندما نجري تغييرات ، سيتم نشر السياسة المحدثة على هذه الصفحة بتاريخ "آخر تحديث".`
                : `We may update this Privacy Policy from time to time. When we make changes, the updated policy will be posted on this page with a revised "Last Updated" date.`}{" "}
            </p>
          </div>
        </div>

        <div className="row my-4">
          <div className="col">
            <h4 className="">
              {lang === "arabic" ? `اتصل بنا` : "Contact Us"}{" "}
            </h4>
            <p className="privacy-para ">
              {lang === "arabic"
                ? `إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه ، فيرجى الاتصال بنا هنا.`
                : `If you have any questions or concerns about this Privacy Policy, please contact us`}{" "}
              {lang === "arabic" ? <></> : <a href="/#Contact">here</a>}{" "}
            </p>
          </div>
        </div>
      </div>
      <footer className="footer-distributed bg-footer ">
        <div className="container">
          <div className="footer-left">
            <h3>
              <a href="/" className="text-decoration-none">
                <img src={Logo} className="logo-icon" alt="logo" />
                <span>NKFEEK نكفيك</span>
              </a>
            </h3>
            <p className="footer-links">
              <a href="/#Services" className="link-1">
                {" "}
                {lang === "arabic" ? "مقدم الخدمات" : "Services Requester"}
              </a>
              {/* <a href="/#Passenger">Passenger</a> */}
              <a href="/#Contact">{lang === "arabic" ? "اتصال" : "Contact"}</a>
            </p>
            <div className="footer-company-name">
              <div style={{ display: "inline-block" }}> NKFEEK &nbsp;</div>
              <div style={{ display: "inline-block" }}>نكفيك</div>
              <div style={{ display: "inline-block" }}>&nbsp; © 2023 </div>
            </div>
          </div>

          <div className="footer-center">
            {/* <div>
                            <IoMdCall className='fs-4 text-light pe-2' />
                            <p><a href="tel:+1234567890">+123567890</a></p>
                        </div>
                        <div>
                            <IoMdMailOpen className='fs-4 text-light pe-2' />
                            <p><a href="mailto:support@nakfeek.com">support@nakfeek.com</a></p>
                        </div> */}
          </div>

          <div className="footer-right">
            <div className="btn-download">
              <button className="btn px-0">
                <a
                  href="https://apps.apple.com/us/app/nkfeek-%D9%86%D9%83%D9%81%D9%8A%D9%83/id6472803579"
                  target="_blank"
                  className="market-btn apple-btn "
                  role="button"
                >
                  <span className="market-button-subtitle">
                    {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                  </span>
                  <span className="market-button-title">
                    {lang === "arabic" ? "متجر التطبيقات" : "App Store"}
                  </span>
                </a>
              </button>
              <br />
              <button className="btn px-0">
                <a
                  href="https://play.google.com/store/apps/details?id=com.aw.taxiapp.sa"
                  target="_blank"
                  rel="noopener"
                  className="market-btn google-btn"
                  role="button"
                >
                  <span className="market-button-subtitle">
                    {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                  </span>
                  <span className="market-button-title">
                    {lang === "arabic" ? "تطبيقات جوجل" : "Google Play"}
                  </span>
                </a>
              </button>
            </div>
            <div className="footer-icons">
              {/* <a href="https://www.facebook.com/"><RiFacebookFill /></a>
                            <a href="https://twitter.com/login"><BsTwitter /></a>
                            <a href="https://in.linkedin.com/"><FaLinkedinIn /></a>
                            <a href="https://www.google.com/intl/en_in/gmail/about/"><BsGoogle /></a> */}
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid bg-footer">
        <div className="container ">
          <div className="col d-flex flex-row  py-3 privacy ">
            <Link to="/privacy" className="text-decoration-none">
              <p className=" mb-0 text-light me-3 p-content">
                {lang === "arabic" ? "سياسة الخصوصية" : "Privacy Policy"}
              </p>
            </Link>
            <Link to="/terms" className="text-decoration-none">
              {" "}
              <p className="mb-0 text-light p-content">
                {lang === "arabic" ? "البنود و الظروف" : "Terms & Conditions"}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
