import React from "react";
import "./about.css";
import about from "../../assests/svg/taxi-about.svg";
import { useState } from "react";
import { useEffect } from "react";
const About = () => {
  // const queryParams = new URLSearchParams(window.location.search);
  // const lang = queryParams.get("lang");
   const lang = localStorage.getItem("lang" ) 
 
  return (
    <>
      <div className="container-xxl py-5 my-5">
        <div className="row main py-3 ">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex flex-column justify-content-center text-center">
            <h1 className="head-driver">
              {" "}
              {lang === "arabic" ? "من نحن" : "Who Are We"}
            </h1>
            <h5>
              {" "}
              {lang === "arabic"
                ? "نحن نربط الخدمة بمزود الخدمة"
                : "We Link A Service With The Service Requester"}
            </h5>
            <p className="text-center ">
              {lang === "arabic"
                ?  " والتي قمنا من خلالها  بربط عدد  من مقدمي  خدمة السائق  والفئات المختلفة بما في ذلك أصحاب التوصيل المدرسي والعقود الشهرية والطلبات الخاصة والعديد من مقدمي الخدمات الآخرين الذين تحتاجهم بشكل دائم، ونحن بدورنا نربط طلبك مع مزود الخدمة بدون  تعب البحث طويل والذي يكون أحيانا من خلال مواقع الانترنت والتي تكتشف منها انها غير مناسبة لك نقوم بعرض ما هو متاح بسرعة عند الطلب ومن ثم تقوم بالقرار مع مقدم الخدمة مباشرة بعد مراجعة اهم المعلومات التي تهمك من مقدم الخدمة مع عرض السعر الذي يقدمه مقدم الخدمة دون اي تكاليف اضافية من كلا الطرفين فقط سيكون هناك رسوم رمزية جدا عندما تصل لطلبك وتتأكد من أنه مناسب لك  ونضيف لك خدمة التتبع وهذا مع خاصية GPS في تطبيقنا ، كل ما عليك فعله والبحث عن طلبك وستجد طلبك بكل سهولة  "
                : "From which we  have connected a number of chauffeur service Requester and  different categories, including school delivery owners, monthly contracts, special requests, and many other service Requester that you need permanently, and we, in turn, link your request with the service provider without  us long search, which is sometimes through Internet sites, from which you discover that it is not suitable for you, we display what is available quickly on demand, and you then decide with the service provider directly after Review the most important information that interests you from the service provider with the price offer offered by the service provider without any additional costs from both parties only There will be a very small fee when you arrive for your request and make sure that it is suitable for you and we add you the tracking service and this with the  GPS feature in our application, all you have to do and search for your request and you will find your request with ease "}
            </p>
            <div className="btn-download">
              <button className="btn">
                <a
                  href="https://apps.apple.com/us/app/nkfeek-%D9%86%D9%83%D9%81%D9%8A%D9%83/id6472803579"
                  target="_blank"
                  className="market-btn apple-btn"
                  role="button"
                >
                  <span className="market-button-subtitle">
                    {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                  </span>
                  <span className="market-button-title">
                    {lang === "arabic" ? "متجر التطبيقات" : "App Store"}
                  </span>
                </a>
              </button>
              <button className="btn">
                <a
                  href="https://play.google.com/store/apps/details?id=com.aw.taxiapp.sa"
                  target="_blank"
                  className="market-btn google-btn"
                  role="button"
                >
                  <span className="market-button-subtitle">
                    {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                  </span>
                  <span className="market-button-title">
                    {lang === "arabic" ? "تطبيقات جوجل" : "Google Play"}
                  </span>
                </a>
              </button>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-sm-5 mt-5">
            <img src={about} alt="img" className="w-100" />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
