import React from "react";
import "./navbar.css";
import Logo from "../../assests/svg/logo.svg";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
 
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
  });
 
  const navigate = useNavigate();
  const translate = (data) => { 
    // navigate({ 
    //   search: `?lang=${data}`,
    // });
    window.location.reload();
    localStorage.setItem("lang", data)
  }; 
    const lang = localStorage.getItem('lang'); 
 
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <nav className="navbar">
            <div className="navbar-container container">
              <input type="checkbox" name="" id="" />
              <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              </div>
              <ul className="menu-items">
                <li>
                  <a href="/#Services" className="nav-anchor  ">
                    { lang === 'arabic' ? "مقدم الخدمات" : "Services Requester" }
                    
                  </a>
                </li>
                {/* <li><a href="/#Passenger" className='nav-anchor '>Passenger</a></li> */}
                <li>
                  <a href="/#Contact" className="nav-anchor ">
                  { lang === 'arabic' ? "اتصال" : "Contact" }
                    
                  </a>
                </li>
                <div className="dropdown">
                  <button
                    className=" ms-3 btn btn-secondary dropdown-toggle drop-d"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                      { lang === 'arabic' ? "عربي" : "English" }
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" onClick={()=>translate(lang === 'arabic' ? "english" : "arabic")}>
                      { lang === 'arabic' ? "English" : "Arabic" } 
                      </a>
                    </li>
                    {/* <li>
                      <a class="dropdown-item"  onClick={()=>translate("arabic")}>
                        Arabic
                      </a>
                    </li> */}
                  </ul>
                </div>
                {/* <li><a href="/login" className='nav-anchor' data-bs-toggle="modal" data-bs-target="#exampleModal" >Login</a></li> */}
              </ul>
              <h1 className="logo">
                <a href="/">
                  <img src={Logo} className="logo-icon" alt="logo" />
                  <span>NKFEEK نكفيك</span>
                </a>
              </h1>
            </div>
          </nav>
        </div>
      </div>
       
    </>
  );
};

export default Navbar;
