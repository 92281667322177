import React from "react";
import { useForm } from "react-hook-form";
import { BiError } from "react-icons/bi";
import Contactimg from "../../assests/svg/Contact.svg";
import "./contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  // const queryParams = new URLSearchParams(window.location.search);
  // const lang = queryParams.get('lang');
  const onSubmit = async (data, e) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        `https://nakfeek.com/api/taxi/common/sendQuote`,
        requestOptions
      );
      const res = await response.json();
      if (res.data.status === true) {
        toast.success(
          "Thanks for contacting us! We will be in touch with you shortly."
        );
        reset({
          name: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const lang = localStorage.getItem("lang");
  return (
    <> 
      <div className="container-xxl py-5 my-4" id="Contact">
        <div className="row my-5 py-5">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <h5 className="text-start ps-3">
              {lang === "arabic" ? "ابقى على تواصل" : "Get In Touch"}
            </h5>
            <h6 className="text-start ps-3">
              {lang === "arabic"
                ? "إذا كان لديك أي أسئلة أو استفسارات حول NKFEEK نكفيك ، يمكنك التواصل معنا عبر النموذج المعطى."
                : "If you have any questions or queries about NKFEEK نكفيك, you can reach us via the form given."}
            </h6>
            <img src={Contactimg} alt="ConatcImage" className="contact-svg" />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-start d-flex justify-content-center align-content-center form-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <div className="row ">
                <div className="col input-container ic1">
                  <input
                    {...register("name", { required: true })}
                    placeholder=" "
                    className="w-100 input "
                    id="Name"
                  />
                  <div className="cut"></div>
                  <label htmlFor="Name" className="placeholderss">
                    {lang === "arabic" ? "اسم*" : "Name*"}
                  </label>
                  {errors.name && (
                    <p className="text-danger">
                      {" "}
                      <BiError />{" "}
                      {lang === "arabic"
                        ? "هذه الخانة مطلوبه"
                        : "This field is required"}
                    </p>
                  )}
                </div>
                <div className="col input-container ic2">
                  <input
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    placeholder=" "
                    className="w-100 input"
                  />
                  <div className="cut"></div>
                  <label htmlFor="email" className="placeholderss">
                    {lang === "arabic" ? "بريد إلكتروني*" : "Email*"}{" "}
                  </label>
                  {errors.email && errors.email.type === "required" && (
                    <p className="text-danger">
                      {" "}
                      <BiError />{" "}
                      {lang === "arabic"
                        ? "هذه الخانة مطلوبه"
                        : "This field is required"}
                    </p>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <p className="text-danger">
                      {" "}
                      <BiError />{" "}
                      {lang === "arabic"
                        ? "غير صحيح البريد الإلكتروني"
                        : "Incorrect Email"}
                    </p>
                  )}
                </div>
              </div>
              <div className="row my-3  ">
                {/* <div className="col mt-5 mt-sm-2 mt-md-2 mt-lg-2 ">
                                    <p className='mb-0'>Are You a :</p>
                                    <input
                                        {...register("type", {
                                            required: true
                                        })}
                                        type="radio"
                                        value="Driver"
                                        className='mx-2'
                                    />
                                    Driver
                                    <input
                                        {...register("type", {
                                            required: true
                                        })}
                                        type="radio"
                                        value="Passenger"
                                        className='mx-2'
                                    />
                                    Passenger <br />
                                    {errors.type && errors.type.type === "required" && (<span className='text-danger'> <BiError /> This field is required</span>)}
                                </div> */}
              </div>
              <div className="row">
                <div className="col input-container ic2">
                  <textarea
                    id="msg"
                    {...register("message", { required: true })}
                    className="w-100 input"
                    placeholder=" "
                    style={{ height: "120px" }}
                  />
                  <div className="cut cut-short"></div>
                  <label htmlFor="msg" className="placeholderss">
                    {lang === "arabic" ? "رسالة*" : "Message*"}{" "}
                  </label>
                  {errors.message && (
                    <p className="text-danger">
                      {" "}
                      <BiError />{" "}
                      {lang === "arabic"
                        ? "هذه الخانة مطلوبه"
                        : "This field is required"}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col mt-4">
                  <button className="mt-4 btn  btn-outline-success">
                    {lang === "arabic" ? "يُقدِّم" : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
