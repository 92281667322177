import React from "react";
import "./driversection.css";
import img1 from "../../assests/images/Nakeef_Image.jpg";
const DriverSection = () => {
  // const queryParams = new URLSearchParams(window.location.search);
  // const lang = queryParams.get('lang');
  const lang = localStorage.getItem("lang");
  return (
    <div className="container-fluid bg-highlight py-5" id="Services">
      <div className="container-xxl  py-5 driver-bg">
        <h1 className="head-driver">
          {lang === "arabic" ? "مقدمو الخدمة" : "Service Requester"}
        </h1>
        <div className="row text-start">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <img src={img1} alt="car" className="w-100" />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            {/* <h6 className="head-driver">What we offer drivers</h6> */}
            <p>
              {lang === "arabic"
                ? "يحرص مقدم الخدمة على الوصول إلى العميل المناسب له سواء كنت سائقا أو مقدم خدمة آخر، ونوفر  لك العميل المناسب حسب رغبتك أثناء تعبئة طلبك للشريحة المستهدفة ونوعها.في تطبيقنا ، نلبي احتياجاتك"
                : "The service Requester is keen to reach the right customer for him  whether you are a driver or another service Requester, and we provide you with the right customer according to your desire while filling out your application for the target segment and its type."}
            </p>
            <p>
              {lang === "arabic"
                ? "في تطبيقنا ، نلبي احتياجاتك."
                : "In our app, we meet your needs."}
            </p>
          </div>
        </div>
        {/* <div className="row  px-md-5 px-sm-0">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
            <div className="col text-start">
              <h5 className="head-driver">0% commission</h5>
              <p className="driver-para ">
                Why buy back the work that’s rightfully yours? With NKFEEK
                نكفيك, you keep all the fare, all the time. We make street work
                and app work worth the same.
              </p>
            </div>
            <div className="col text-start">
              <h5 className="head-driver">Owned and operated by drivers</h5>
              <p className="driver-para ">
                As cabbies ourselves, we know the pressures you’re under.
                They’re exactly why we started NKFEEK نكفيك. The trade is
                stronger together.
              </p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="col text-start">
              <h5 className="head-driver">Share in the success</h5>
              <p className="driver-para ">
                Our Drivers Reward Scheme means all drivers enjoy a profit
                share. The more jobs you do, the greater your cut. Find out more
                here.
              </p>
            </div>
            <div className="col text-start">
              <h5 className="head-driver">Private Hire</h5>
              <p className="driver-para ">
                No ifs. No buts. Not now, not ever. This is set in stone.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DriverSection;
