import React from "react";
import "./footer.css";
import Logo from "../../assests/svg/taxi-another.svg";
import { IoMdCall, IoMdMailOpen } from "react-icons/io";
import { RiFacebookFill } from "react-icons/ri";
import { BsTwitter, BsGoogle, BsLightning } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { BiCoinStack } from "react-icons/bi";
import { AiOutlineIdcard } from "react-icons/ai";
import { Link } from "react-router-dom";
import Mada from "../../assests/svg/mada.svg";
const Footer = () => {
  // const queryParams = new URLSearchParams(window.location.search);
  // const lang = queryParams.get('lang');
  const lang = localStorage.getItem("lang");
  return (
    <>
      <div className="container-fluid px-0">
        <div className="container-xxl bg-footer">
          <footer className="footer-distributed">
            <div className="footer-left">
              <h3>
                <a href="/" className="text-decoration-none">
                  <img src={Logo} className="logo-icon" alt="logo" />
                  <span>NKFEEK نكفيك</span>
                </a>
              </h3>
              <p className="footer-links">
                <a href="/#Services" className="link-1">
                  {lang === "arabic" ? "مقدم الخدمات" : "Services Requester"}
                </a>
                {/* <a href="/#Passenger">Passenger</a> */}
                <a href="/#Contact">
                  {lang === "arabic" ? "اتصال" : "Contact"}
                </a>
              </p>
              <div className="footer-company-name">
                <div style={{ display: "inline-block" }}> NKFEEK &nbsp;</div>
                <div style={{ display: "inline-block" }}>نكفيك</div>
                <div style={{ display: "inline-block" }}>&nbsp; © 2023 </div>
              </div>
            </div>

            <div className="footer-center">
              {/* <div>
                                <IoMdCall className='fs-4 text-light pe-2' />
                                <p><a href="tel:+1234567890">+1234567890</a></p>
                            </div>
                            <div>
                                <IoMdMailOpen className='fs-4 text-light pe-2' />
                                <p><a href="mailto:support@nakfeek.com">support@nakfeek.com</a></p>
                            </div> */}
            </div>

            <div className="footer-right">
              <div className="btn-download">
                <button className="btn px-0">
                  <a
                    href="https://apps.apple.com/us/app/nkfeek-%D9%86%D9%83%D9%81%D9%8A%D9%83/id6472803579"
                    target="_blank"
                    className="market-btn apple-btn "
                    role="button"
                  >
                    <span className="market-button-subtitle">
                      {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                    </span>
                    <span className="market-button-title">
                      {lang === "arabic" ? "متجر التطبيقات" : "App Store"}
                    </span>
                  </a>
                </button>
                <br />
                <button className="btn px-0">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.aw.taxiapp.sa"
                    target="_blank"
                    rel="noopener"
                    className="market-btn google-btn"
                    role="button"
                  >
                    <span className="market-button-subtitle">
                      {lang === "arabic" ? "تنزيل من ملف" : "Download on the"}
                    </span>
                    <span className="market-button-title">
                      {lang === "arabic" ? "تطبيقات جوجل" : "Google Play"}
                    </span>
                  </a>
                </button>
              </div>
              <div className="footer-icons">
                {/* <a href="https://www.facebook.com/"><RiFacebookFill /></a>
                            <a href="https://twitter.com/login"><BsTwitter /></a>
                            <a href="https://in.linkedin.com/"><FaLinkedinIn /></a>
                            <a href="https://www.google.com/intl/en_in/gmail/about/"><BsGoogle /></a> */}
              </div>
            </div>
          </footer>
          {/* <div className="d-flex justify-content-between">
            <div className="d-flex flex-row ps-md-5  py-3 privacy">
              <Link to="/privacy" className="text-decoration-none">
                <p className=" mb-0 text-light me-3 p-content">
                  {lang === "arabic" ? "سياسة الخصوصية" : "Privacy Policy"}
                </p>
              </Link>
              <Link to="/terms" className="text-decoration-none">
                {" "}
                <p className="mb-0 text-light p-content">
                  {lang === "arabic" ? "البنود و الظروف" : "Terms & Conditions"}
                </p>
              </Link>
            </div>
            <div className="my-4 me-5 pe-5">
              <img className="mada-logo" src={Mada} />
            </div>
          </div> */}
        </div>
        <div className="d-flex justify-content-between">
            <div className="d-flex flex-row ps-md-5  py-3 privacy">
              <Link to="/privacy" className="text-decoration-none">
                <p className=" mb-0 text-dark me-3 p-content">
                  {lang === "arabic" ? "سياسة الخصوصية" : "Privacy Policy"}
                </p>
              </Link>
              <Link to="/terms" className="text-decoration-none">
                {" "}
                <p className="mb-0 text-dark p-content">
                  {lang === "arabic" ? "البنود و الظروف" : "Terms & Conditions"}
                </p>
              </Link>
            </div>
            <div className="my-4 me-5 pe-5">
              <img className="mada-logo" src={Mada} />
            </div>
          </div>
      </div>
    </>
  );
};

export default Footer;
